// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `span {
  /* color: #e0e0e0; */
  color: #000000;
}

.title {
  margin-bottom: 20px;
}

.icon-color {
  color: #e0e0e0;
}
`, "",{"version":3,"sources":["webpack://./src/components/CommunicationMethod/style.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,cAAc;AAChB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB","sourcesContent":["span {\n  /* color: #e0e0e0; */\n  color: #000000;\n}\n\n.title {\n  margin-bottom: 20px;\n}\n\n.icon-color {\n  color: #e0e0e0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
