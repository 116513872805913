import CryptoJs from 'crypto-js';

const APP_ID = '49939c7b';
const API_KEY = '17c4ac9b72497e7fb815100ef2067f22';
const API_SECRECT = 'ZTRjOTZlNThjN2JiMGY1YThhMWFlNjM5';
var _websocket = null;
var _dataQueue = [];
var _running = false;
var _isFirst = true;
var _recognizedText = '';

export function _evaluate(base64Data, isEnd = false) {
  if (_websocket !== null && _websocket.readyState !== WebSocket.OPEN) {
    return;
  }
  let frame = 1;
  if (_isFirst) {
    frame = 0;
    _isFirst = false;
  }
  if (isEnd) {
    frame = 2;
  }
  const data = _createData(frame, base64Data);
  _dataQueue.push(data);
  if (_websocket === null || _running) {
    return;
  }
  _runEvaluate();
}
export function _addListener(option) {
  try {
    _recognizedText = '';
    _isFirst = true;
    _dataQueue = [];
    _connect(option);
  } catch (error) {
    if (option.onClosed) {
      option.onClosed(1006, '语音识别失败，发送失败');
    }
  }
}
function _connect(option) {
  let uri = 'ws://';
  if (window.location.protocol === 'https:') {
    uri = 'wss://';
  }
  const host = 'iat-api.xfyun.cn';
  const api = '/v2/iat';
  const date = new Date().toUTCString();
  const authorization = _getAuthorization(host, api, date);
  uri +=
    host +
    api +
    '?authorization=' +
    authorization +
    '&date=' +
    date +
    '&host=' +
    host;
  try {
    _websocket = new WebSocket(uri);
    _websocket.onmessage = event => {
      const onAnswer =
        option.onAnswer instanceof Function ? option.onAnswer : result => {};
      _getResult(event, onAnswer);
    };
    _websocket.onerror = error => {
      console.log('websocket发生异常');
      console.log(error);
      // 判断websocket是否已断连
      if (_websocket.readyState === WebSocket.OPEN) {
        _disconnect(1006, '发生异常');
      }
    };
    _websocket.onclose = closeEvent => {
      const code = closeEvent.code;
      _websocket = null;
      if (code !== 1000 && option.onClosed instanceof Function) {
        let reason = '';
        switch (code) {
          case 1006:
            reason = '语音识别异常';
            break;
          case 3000:
            reason = '未检测到语音';
            break;
          case 3001:
            reason = '语音识别失败';
            break;
        }
        option.onClosed(code, reason);
      }
    };
  } catch (error) {
    throw error;
  }
}
function _disconnect(code) {
  // code 1000-Success 3000-NoAudio 3001-Fail
  if (_websocket !== null) {
    _websocket.close(code);
  }
}
function _getAuthorization(host, api, date) {
  const requestLine = `GET ${api} HTTP/1.1`;
  const stringNeedSigned = `host: ${host}\ndate: ${date}\n${requestLine}`;
  const signature = CryptoJs.enc.Base64.stringify(
    CryptoJs.HmacSHA256(stringNeedSigned, API_SECRECT)
  );
  const stringNeedAuthed = `api_key="${API_KEY}", algorithm="hmac-sha256", headers="host date request-line", signature="${signature}"`;
  return CryptoJs.enc.Base64.stringify(
    CryptoJs.enc.Utf8.parse(stringNeedAuthed)
  );
}
function _createData(frame, base64Data = '') {
  const _data = {
    data: {
      status: frame,
      format: 'audio/L16;rate=16000',
      encoding: 'raw',
      audio: base64Data,
    },
  };
  if (frame === 0) {
    _data.common = {
      app_id: APP_ID,
    };
    _data.business = {
      language: 'zh_cn',
      domain: 'iat',
      accent: 'mandarin',
      vad_eos: 5000,
    };
  }
  return JSON.stringify(_data);
}
async function _runEvaluate() {
  if (_dataQueue.length === 0) {
    _running = false;
    return;
  }
  _running = true;
  const data = _dataQueue.splice(0, 1)[0];
  if (_websocket !== null && _websocket.readyState === WebSocket.OPEN) {
    _websocket.send(data);
  }
  await new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, 40);
  });
  _runEvaluate();
}
function _getResult(event, onAnswer) {
  const response = JSON.parse(event.data);
  console.log('识别结果:', response);
  if (response.code === 0 && response.message === 'success') {
    const { result = {}, status } = response.data || {};
    const { ws = [] } = result;
    if (ws.length > 0) {
      const text = ws
        .map(item => {
          const { cw = [] } = item || {};
          if (cw.length === 0) {
            return '';
          }
          return cw.map(item => item.w || '').join('');
        })
        .join('');
      _recognizedText += text;
    }
    if (status === 2) {
      const word = _recognizedText;
      _recognizedText = '';
      // 若识别为空则可能触发静音检测
      if (word === '') {
        _disconnect(3000);
        console.log('静音检测');
        return;
      }
      onAnswer({
        success: true,
        data: word,
      });
      _disconnect(1000);
    }
    return;
  }
  _disconnect(3001);
  console.log('识别失败');
}
