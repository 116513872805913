import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getHostName } from '../utils/urlUtils';
import { postJson } from '../utils/util';
import {
  Col,
  Row,
  Spacer,
  Text,
  Input,
  Button,
  Loading,
} from '@nextui-org/react';

const Login = ({
  isLoggedIn,
  setToken,
  setUser,
  isMobile,
  openToast,
  closeToast,
}) => {
  const navigator = useNavigate();
  const [tab, setTab] = useState('phone');
  const lock = useRef(false);
  const [cutdown, setCutdown] = useState(60);
  const timer = useRef(null);
  const [isSended, setIsSended] = useState(false);
  const phone = useRef(null);
  const code = useRef(null);
  // unint-未初始化 load-加载中 inted-已加载
  const [qrcodeStatus, setQrcodeStatus] = useState('unint');
  const [src, setSrc] = useState('');

  useEffect(() => {
    return () => {
      clearTimer();
    };
  }, []);

  const changeTab = tabName => {
    if (tabName === tab) {
      return;
    }
    setTab(tabName);
    // 手机
    if (tabName === 'phone') {
      setQrcodeStatus('unint');
      phone.current = null;
      code.current = null;
    }
    // 微信
    if (tabName === 'wx') {
      clearTimer();
      getWxLogin();
    }
  };

  const validatePhone = () => {
    if (!phone.current) {
      openToast('手机号输入框未初始化', 'error');
      return false;
    }
    const text = phone.current.value;
    if (text === null || text === '') {
      openToast('请输入手机号', 'error');
      return false;
    }
    if (!/^1[3456789]\d{9}$/.test(text)) {
      openToast('手机号格式错误', 'error');
      return false;
    }
    return true;
  };

  const validateCode = () => {
    if (!code.current) {
      openToast('验证码输入框未初始化', 'error');
      return false;
    }
    const text = code.current.value;
    if (text === null || text === '') {
      openToast('请输入验证码', 'error');
      return false;
    }
    return true;
  };

  const getCode = () => {
    if (lock.current) {
      return;
    }
    if (!validatePhone()) {
      return;
    }
    lock.current = true;
    const scheme = window.location.protocol;
    const url =
      scheme +
      '//' +
      getHostName() +
      '/send_sms_code?phone=' +
      phone.current.value;
    postJson(url, {
      success: _ => {
        setIsSended(true);
        timer.current = setInterval(() => {
          setCutdown(cutdown => {
            if (cutdown === 1) {
              clearTimer();
            }
            return cutdown - 1;
          });
        }, 1000);
      },
      fail: error => {
        openToast(error.message, 'error');
      },
      complete: () => {
        lock.current = false;
      },
    });
  };

  const clearTimer = () => {
    if (timer.current !== null) {
      clearInterval(timer.current);
      timer.current = null;
      setIsSended(false);
      setCutdown(60);
    }
  };

  const getWxLogin = async () => {
    if (qrcodeStatus === 'load') {
      return;
    }
    setQrcodeStatus('load');
    const scheme = window.location.protocol;
    const url = scheme + '//' + getHostName() + '/login';
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 200) {
        const result = await response.json();
        if (result.code === 200) {
          const { url } = result.data || {};
          if (url !== undefined && url !== '') {
            setSrc(url);
          }
          setQrcodeStatus('inited');
        } else {
          throw new Error(result.msg);
        }
      } else {
        throw new Error(response.text);
      }
    } catch (error) {
      setQrcodeStatus('unint');
      console.log('加载二维码失败:' + error.message);
      openToast('加载二维码失败', 'error');
    }
  };

  const loginByPhone = async () => {
    if (lock.current) {
      return;
    }
    if (!validatePhone()) {
      return;
    }
    if (!validateCode()) {
      return;
    }

    lock.current = true;
    const scheme = window.location.protocol;
    const url =
      scheme +
      '//' +
      getHostName() +
      '/mobile_login?phone=' +
      phone.current.value +
      '&code=' +
      code.current.value;
    postJson(url, {
      data: {},
      success: data => {
        openToast('登录成功', 'success');
        isLoggedIn.current = true;
        const userInfo = data;
        const { token = '' } = userInfo;
        setToken(token);
        setUser(userInfo);
        // 缓存登录信息
        localStorage.setItem('TOKEN', token);
        localStorage.setItem('USERINFO', JSON.stringify(userInfo));
        navigator('/');
      },
      fail: error => {
        openToast(error.message, 'error');
      },
      complete: () => {
        lock.current = false;
      },
    });
  };

  return (
    <div className='login-wrapper'>
      <Spacer y={1}></Spacer>
      <div className='login-tabbar'>
        <div
          className={`login-tab ${tab === 'phone' ? 'login-tab-selected' : ''}`}
          onClick={() => {
            changeTab('phone');
          }}
        >
          手机登录
        </div>
        <div
          className={`login-tab ${tab === 'wx' ? 'login-tab-selected' : ''}`}
          onClick={() => {
            changeTab('wx');
          }}
        >
          微信登录
        </div>
      </div>
      {tab === 'wx' ? (
        <div
          className={`${isMobile ? 'login-content-mobile' : 'login-content'}`}
        >
          {qrcodeStatus === 'unint' ? (
            <div></div>
          ) : qrcodeStatus === 'load' ? (
            <div className='wx-qrcode'>
              <Loading></Loading>
            </div>
          ) : (
            <iframe className='wx-qrcode' src={src} frameBorder='0'></iframe>
          )}
        </div>
      ) : (
        <div
          className={`${isMobile ? 'login-content-mobile' : 'login-content'}`}
        >
          <div className='login-form'>
            <Col>
              <Text>手机号码</Text>
              <Input
                ref={phone}
                aria-label='phone'
                placeholder='请输入手机号码'
                maxLength={11}
              ></Input>
            </Col>
            <Col>
              <Text>验证码</Text>
              <Row align='center'>
                <Input
                  ref={code}
                  aria-label='code'
                  placeholder='请输入验证码'
                  maxLength={6}
                ></Input>
                <Spacer x={1}></Spacer>
                {isSended ? (
                  <Text>{cutdown}s</Text>
                ) : (
                  <Button size='xs' onPress={getCode}>
                    获取验证码
                  </Button>
                )}
              </Row>
            </Col>
            <Spacer y={1}></Spacer>
            <Button onPress={loginByPhone}>登录</Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
