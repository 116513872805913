import { useState, useRef, useEffect } from 'react';
import useConversation from './useConversation.js';

const useRecord = (
  isConnected,
  audioSent,
  callActive,
  send,
  closeSocket,
  recognize,
  isResponding
) => {
  const [isRecording, setIsRecording] = useState(false);
  const mediaRecorder = useRef(null);
  const audioContext = useRef(null);
  const audioSource = useRef(null);
  const processNode = useRef(null);
  const notProcess = useRef(false);

  useEffect(() => {
    notProcess.current = isResponding;
  }, [isResponding]);

  const connectMicrophone = () => {
    if (mediaRecorder.current) return;
    if (audioContext.current) {
      audioContext.current.close();
      audioContext.current = null;
      audioSource.current = null;
      processNode.current = null;
    }
    navigator.mediaDevices
      .getUserMedia({
        audio: {
          channelCount: 1,
          echoCancellation: true,
        },
      })
      .then(stream => {
        // audio process init
        const AudioContext = window.AudioContext || window.webkitAudioContext;
        const ctx = new AudioContext({
          sampleRate: 16000,
        });
        audioSource.current = ctx.createMediaStreamSource(stream);
        processNode.current = ctx.createScriptProcessor(1024, 1, 1);
        // listen process event
        processNode.current.onaudioprocess = event => {
          // AI语音合成期间不执行操作
          if (notProcess.current) {
            return;
          }
          const input = event.inputBuffer.getChannelData(0);
          const output = _floatTo16BitPCM(input);
          const buffer = output.buffer;
          const base64Data = _arrayBufferToBase64(buffer);
          // Call View模式下使用评测
          if (callActive.current) {
            useConversation.saveAudio(buffer, base64Data);
          }
          recognize(base64Data);
        };
        audioContext.current = ctx;
        // record init
        mediaRecorder.current = new MediaRecorder(stream);
        mediaRecorder.current.onstart = () => {
          console.log('record on start');
          audioSource.current.connect(processNode.current);
          processNode.current.connect(audioContext.current.destination);
          // Call View模式下使用评测
          if (callActive.current) {
            useConversation.createMessage();
          }
        };
        mediaRecorder.current.onstop = () => {
          console.log('record on stop');
          audioSource.current.disconnect(processNode.current);
          processNode.current.disconnect(audioContext.current.destination);
          const base64Data = _arrayBufferToBase64(new ArrayBuffer(0));
          recognize(base64Data, true);
        };
      })
      .catch(err => {
        console.log('An error occurred: ' + err);
        if (err.name === 'NotAllowedError') {
          alert(
            'Permission Denied: Please grant permission to access the microphone and refresh the website to try again!'
          );
        } else if (err.name === 'NotFoundError') {
          alert(
            'No Device Found: Please check your microphone device and refresh the website to try again.'
          );
        }
      });
  };

  const startRecording = () => {
    console.log('startRecording');
    if (!mediaRecorder.current) return;
    mediaRecorder.current.start();
    setIsRecording(true);
  };

  const stopRecording = () => {
    console.log('stopRecording');
    if (!mediaRecorder.current) return;
    mediaRecorder.current.stop();
    setIsRecording(false);
  };
  const closeMediaRecorder = () => {
    if (isRecording) {
      stopRecording();
    }
    mediaRecorder.current = null;
  };

  const _floatTo16BitPCM = input => {
    const bitDepth = 16;
    const bytesPerSample = bitDepth / 8;
    let offset = 0;
    let buffer = new ArrayBuffer(input.length * bytesPerSample);
    let output = new DataView(buffer);
    for (let i = 0; i < input.length; i++, offset += 2) {
      let s = Math.max(-1, Math.min(1, input[i]));
      output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
    }
    return output;
  };

  const _arrayBufferToBase64 = buffer => {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  };

  return {
    isRecording,
    setIsRecording,
    connectMicrophone,
    startRecording,
    stopRecording,
    closeMediaRecorder,
  };
};

export default useRecord;
