/**
 * src/components/Auth/SignOut.jsx
 * sign out
 *
 * created by Lynchee on 7/20/23
 */

import React from 'react';
import { Navbar, Dropdown, Avatar } from '@nextui-org/react';
import { useNavigate } from 'react-router-dom';
import './styles.css';

const SignOut = ({ isLoggedIn, user, handleDisconnect, setUser }) => {
  const navigate = useNavigate();
  const signout = async () => {
    isLoggedIn.current = false;
    setUser(null);
    // 清除登录信息
    localStorage.removeItem('TOKEN');
    localStorage.removeItem('USERINFO');
  };

  const handleSignout = async () => {
    await signout();
  };

  const handleDropdownAction = async actionKey => {
    if (actionKey === 'logout') {
      // when a dropdown button is pressed using its "key" ,which we set, we can tell when its pressed , so when the key "logout" is pressed we sign the user out, in the future you can use the key "profile" to navigate the user to his dashboard for example
      await handleSignout();
    } else if (actionKey === 'create') {
      navigate('/create');
    } else if (actionKey === 'delete') {
      navigate('/delete');
    } else if (actionKey === 'user') {
      navigate('/user');
    }
  };

  return (
    <Navbar.Content
      css={{
        '@xs': {
          w: '12%',
          jc: 'flex-end',
        },
      }}
    >
      <Dropdown placement='bottom-right' isBordered='true'>
        <Navbar.Item>
          <Dropdown.Trigger>
            {user.headimgurl === null || user.headimgurl === '' ? (
              <Avatar
                className='usericon'
                size='md'
                color='primary'
                text={user.name || user.nickname || user.phone.substring(7)}
                textColor='white'
              />
            ) : (
              <Avatar className='usericon' size='md' src={user.headimgurl} />
            )}
          </Dropdown.Trigger>
        </Navbar.Item>
        <Dropdown.Menu
          aria-label='User menu actions'
          onAction={actionKey => handleDropdownAction(actionKey)}
        >
          <Dropdown.Item key='user' className='dropdown-item'>
            User Info
          </Dropdown.Item>
          <Dropdown.Item key='create' className='dropdown-item'>
            Create a character
          </Dropdown.Item>
          <Dropdown.Item key='delete' className='dropdown-item'>
            Delete a character
          </Dropdown.Item>
          <Dropdown.Item key='logout' className='dropdown-item'>
            Log Out
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Navbar.Content>
  );
};

export default SignOut;
