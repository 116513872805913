import React, { useRef, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { getHostName } from '../utils/urlUtils';
import { get, postJson } from '../utils/util';
import {
  Col,
  Row,
  Text,
  Input,
  Button,
  Spacer,
  Loading,
} from '@nextui-org/react';

const Auth = ({ isLoggedIn, isMobile, setToken, setUser, openToast }) => {
  const location = useLocation();
  const navigator = useNavigate();
  const isInit = useRef(false);
  const wxInfo = useRef(null);
  // wxLogin-登录微信 bindWx-绑定微信 bindPhone-绑定手机号
  const [page, setPage] = useState('');
  // 绑定手机号
  const lock = useRef(false);
  const [cutdown, setCutdown] = useState(60);
  const timer = useRef(null);
  const [isSended, setIsSended] = useState(false);
  const phone = useRef(null);
  const code = useRef(null);

  const validatePhone = () => {
    if (!phone.current) {
      openToast('手机号输入框未初始化', 'error');
      return false;
    }
    const text = phone.current.value;
    if (text === null || text === '') {
      openToast('请输入手机号', 'error');
      return false;
    }
    if (!/^1[3456789]\d{9}$/.test(text)) {
      openToast('手机号格式错误', 'error');
      return false;
    }
    return true;
  };

  const validateCode = () => {
    if (!code.current) {
      openToast('验证码输入框未初始化', 'error');
      return false;
    }
    const text = code.current.value;
    if (text === null || text === '') {
      openToast('请输入验证码', 'error');
      return false;
    }
    return true;
  };

  const getCode = async () => {
    if (lock.current) {
      return;
    }
    if (!validatePhone()) {
      return;
    }
    lock.current = true;
    const scheme = window.location.protocol;
    const url =
      scheme +
      '//' +
      getHostName() +
      '/send_sms_code?phone=' +
      phone.current.value;
    postJson(url, {
      success: _ => {
        setIsSended(true);
        timer.current = setInterval(() => {
          setCutdown(cutdown => {
            if (cutdown === 1) {
              clearTimer();
            }
            return cutdown - 1;
          });
        }, 1000);
      },
      fail: error => {
        openToast(error.message, 'error');
      },
      complete: () => {
        lock.current = false;
      },
    });
  };

  const clearTimer = () => {
    if (timer !== null) {
      clearInterval(timer.current);
      timer.current = null;
      setIsSended(false);
      setCutdown(60);
    }
  };

  const wxLoginByPhone = () => {
    if (lock.current) {
      return;
    }
    if (!validatePhone()) {
      return;
    }
    if (!validateCode()) {
      return;
    }

    lock.current = true;
    const scheme = window.location.protocol;
    const url = scheme + '//' + getHostName() + '/wx_login';
    postJson(url, {
      data: {
        phone: phone.current.value,
        code: code.current.value,
        ...wxInfo.current,
      },
      success: data => {
        openToast('绑定成功', 'success');
        isLoggedIn.current = true;
        const userInfo = data;
        const { token = '' } = userInfo;
        setToken(token);
        setUser(userInfo);
        // 缓存登录信息
        localStorage.setItem('TOKEN', token);
        localStorage.setItem('USERINFO', JSON.stringify(userInfo));
        navigator('/');
      },
      fail: error => {
        openToast(error.message, 'error');
      },
      complete: () => {
        lock.current = false;
      },
    });
  };

  const getWxInfo = async (code, cb) => {
    const scheme = window.location.protocol;
    const url = scheme + '//' + getHostName() + '/wxinfo?code=' + code;

    get(url, {
      success: data => {
        wxInfo.current = data;
        cb && cb();
      },
      fail: error => {
        openToast(error.message, 'error');
      },
    });
  };

  const bindWx = () => {
    const scheme = window.location.protocol;
    const url = scheme + '//' + getHostName() + '/edit_user_wx';
    var currentUser;
    var id;
    try {
      currentUser = JSON.parse(localStorage.getItem('USERINFO') || '{}');
      id = currentUser.id;
    } catch (_) {
      console.log('绑定微信失败', _);
    }

    postJson(url, {
      data: {
        id,
        ...wxInfo.current,
      },
      success: _ => {
        const newUser = {
          ...currentUser,
          ...wxInfo.current,
        };
        setUser(newUser);
        localStorage.setItem('USERINFO', JSON.stringify(newUser));
        navigator('/user');
      },
      fail: error => {
        openToast(error.message, 'error');
      },
    });
  };

  useEffect(() => {
    if (!isInit.current) {
      isInit.current = true;
      const { search } = location;
      const { code = '' } = queryString.parse(search);
      if (code === '') {
        openToast('微信授权失败', 'error');
        return;
      }
      getWxInfo(code, () => {
        const info = wxInfo.current;
        const isLogin =
          localStorage.getItem('TOKEN') !== null &&
          localStorage.getItem('TOKEN') !== '';
        if (!isLogin) {
          const { token } = info;
          // 已绑定手机号
          if (token !== undefined && token !== null && token !== '') {
            setPage('wxLogin');
            isLoggedIn.current = true;
            setToken(token);
            setUser(info);
            localStorage.setItem('TOKEN', token);
            localStorage.setItem('USERINFO', JSON.stringify(info));
            setTimeout(() => {
              navigator('/');
            }, 1000);
            return;
          }
          setPage('bindPhone');
          return;
        }
        // 绑定微信
        setPage('bindWx');
        bindWx();
      });
    }

    return () => {
      clearTimer();
    };
  }, []);

  if (!isInit.current) {
    return <Loading></Loading>;
  }
  if (page === 'wxLogin') {
    return <div>正在跳转，请勿刷新或者关闭页面...</div>;
  }
  if (page === 'bindWx') {
    return <div>正在绑定微信，请勿刷新或者关闭页面...</div>;
  }
  if (page === 'bindPhone') {
    return (
      <div className='login-wrapper'>
        <div
          className={`${
            isMobile ? 'login-content-mobile' : 'login-content'
          } login-content-no-border`}
        >
          <Text h1>绑定手机号</Text>
          <Spacer y={1}></Spacer>
          <div className='login-form'>
            <Col>
              <Text>手机号码</Text>
              <Input
                ref={phone}
                aria-label='phone'
                placeholder='请输入手机号码'
                maxLength={11}
              ></Input>
            </Col>
            <Col>
              <Text>验证码</Text>
              <Row align='center'>
                <Input
                  ref={code}
                  aria-label='code'
                  placeholder='请输入验证码'
                  maxLength={6}
                ></Input>
                <Spacer x={1}></Spacer>
                {isSended ? (
                  <Text>{cutdown}s</Text>
                ) : (
                  <Button size='xs' onPress={getCode}>
                    获取验证码
                  </Button>
                )}
              </Row>
            </Col>
            <Spacer y={1}></Spacer>
            <Button onPress={wxLoginByPhone}>绑定</Button>
          </div>
        </div>
      </div>
    );
  }
};

export default Auth;
