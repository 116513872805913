// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-container {
  flex-direction: row;
}

.character-item {
  display: flex;
  flex-direction: column;
  &:hover {
    background-color: transparent !important;
    .character-avatar {
      border: 1px solid #617CC2;
    }
  }
}

.character-avatar {
  box-sizing: border-box;
  margin-bottom: 1vw;
}
`, "",{"version":3,"sources":["webpack://./src/components/Characters/style.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB;IACE,wCAAwC;IACxC;MACE,yBAAyB;IAC3B;EACF;AACF;;AAEA;EACE,sBAAsB;EACtB,kBAAkB;AACpB","sourcesContent":[".main-container {\n  flex-direction: row;\n}\n\n.character-item {\n  display: flex;\n  flex-direction: column;\n  &:hover {\n    background-color: transparent !important;\n    .character-avatar {\n      border: 1px solid #617CC2;\n    }\n  }\n}\n\n.character-avatar {\n  box-sizing: border-box;\n  margin-bottom: 1vw;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
