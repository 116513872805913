// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `label {
  margin-top: 8px;
  margin-bottom: 8px;
  /* color: #e0e0e0; */
  color: #000000;
}

.advanced-options {
  display: inline-flex;
  /* background-color: #1b2134; */
  border-radius: 5px;
  margin-bottom: 30px;
  flex-direction: column;
  align-items: flex-start;
}

.quivr-text-input {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/AdvancedOptions/style.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,kBAAkB;EAClB,oBAAoB;EACpB,cAAc;AAChB;;AAEA;EACE,oBAAoB;EACpB,+BAA+B;EAC/B,kBAAkB;EAClB,mBAAmB;EACnB,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,oBAAoB;EACpB,sBAAsB;EACtB,uBAAuB;EACvB,SAAS;EACT,WAAW;AACb","sourcesContent":["label {\n  margin-top: 8px;\n  margin-bottom: 8px;\n  /* color: #e0e0e0; */\n  color: #000000;\n}\n\n.advanced-options {\n  display: inline-flex;\n  /* background-color: #1b2134; */\n  border-radius: 5px;\n  margin-bottom: 30px;\n  flex-direction: column;\n  align-items: flex-start;\n}\n\n.quivr-text-input {\n  display: inline-flex;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: 10px;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
