/**
 * src/components/CallView/index.jsx
 * User can stop or continue the call. Allows audios playing and switch to TextView.
 *
 * created by Lynchee on 7/16/23
 */

import React, { useEffect, useState, useRef } from 'react';
import './style.css';
import { useNavigate } from 'react-router-dom';
import { TbPhoneCall } from 'react-icons/tb';
import { MdCallEnd } from 'react-icons/md';
import { TbMessageChatbot, TbPower, TbShare2 } from 'react-icons/tb';
import IconButton from '../Common/IconButton';
import { setAnalyser } from '../../components/AvatarView';
// utils
import { playAudios } from '../../utils/audioUtils';
import useConversation from '../../hooks/useConversation.js';
import { Button, Text, Modal, useModal, Collapse } from '@nextui-org/react';

const CallView = ({
  isRecording,
  isPlaying,
  isResponding,
  audioPlayer,
  handleStopCall,
  handleContinueCall,
  audioQueue,
  audioContextRef,
  audioSourceNodeRef,
  setIsPlaying,
  handleDisconnect,
  setIsCallView,
  sessionId,
  playAudioFromNode,
  textAreaValue,
  setTextAreaValue,
  isConnecting,
  isConnected,
  connect,
  openToast,
  startRecord,
  stopRecord,
}) => {
  const { initialize, setInitialize } = useState(true);
  const navigate = useNavigate();
  const chatWindowRef = useRef(null);

  const [isOpenning, setIsOpenning] = useState(false);
  const [isOpenned, setIsOpenned] = useState(false);

  useEffect(() => {
    if (isPlaying && audioContextRef.current) {
      playAudios(
        audioContextRef,
        audioPlayer,
        audioQueue,
        setIsPlaying,
        playAudioFromNode,
        audioSourceNodeRef,
        initialize,
        setInitialize
      );
    }
    // 播放录音则关闭用户语音
    if (isPlaying) {
      if (isRecording) {
        stopRecord();
      }
    } else {
      if (isConnected.current && isOpenned && !isRecording) {
        startRecord();
      }
    }
  }, [isPlaying]);

  const handlePowerOffClick = async () => {
    // navigate('/');
    // handleDisconnect();
    if (isRecording) {
      handleStopCall();
    }
    if (isConnected.current) {
      await handleDisconnect();
    }
    navigate('/');
  };

  const openVoiceChat = async () => {
    if (isOpenning) {
      openToast('Calling, Please don`t call again', 'error');
      return;
    }
    setIsOpenning(true);
    setTextAreaValue('');
    try {
      var websocketConnected = false;
      await connect();
      while (!websocketConnected) {
        await new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve();
          }, 100);
        });
        if (isConnected.current) {
          websocketConnected = true;
        }
      }
      setIsOpenning(false);
      setIsOpenned(true);
    } catch (e) {
      setIsOpenning(false);
    }
  };
  const closeVoiceChat = async () => {
    if (isRecording) {
      handleStopCall();
    }
    await handleDisconnect(false);
    setIsOpenned(false);
  };
  const { setVisible, bindings } = useModal();
  const [conversation, setConversation] = useState([]);
  const openEvaluation = () => {
    setVisible(true);
    const currentConversation = useConversation
      .getConversation()
      .filter(item => item.text !== null && item.evaluation !== null);
    setConversation(currentConversation);
  };
  const closeEvaluation = () => {
    setVisible(false);
    setConversation([]);
  };
  const formatPoint = point => {
    return Number(point).toFixed(2) + '%';
  };

  return (
    <div className='call-screen'>
      <div className='call-container'>
        <audio ref={audioPlayer} className='audio-player'>
          <source src='' type='audio/mp3' />
        </audio>
        <div
          className={`sound-wave ${isRecording ? '' : 'stop-animation'}`}
          style={{
            display: isOpenned && isRecording ? 'flex' : 'none',
          }}
        >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div
          style={{
            display: isOpenning ? 'block' : 'none',
          }}
        >
          Calling, Please wait a minute...
        </div>
        {/* {isRecording ? (
          <IconButton
            Icon={MdCallEnd}
            className='icon-red'
            bgcolor='red'
            onClick={handleStopCall}
          />
        ) : (
          <IconButton
            Icon={TbPhoneCall}
            className='icon-green'
            bgcolor='green'
            onClick={handleContinueCall}
          />
        )} */}
      </div>
      <textarea
        className='chat-window'
        readOnly
        draggable='false'
        ref={chatWindowRef}
        value={textAreaValue}
        style={{ display: isOpenned ? 'block' : 'none' }}
      ></textarea>
      <div className='options-container'>
        <IconButton
          Icon={TbPower}
          className='icon-red'
          onClick={handlePowerOffClick}
        />
        {/* <IconButton
          Icon={TbMessageChatbot}
          className='icon-green'
          onClick={() => setIsCallView(false)}
        /> */}
        {/* <IconButton
          Icon={TbShare2}
          disabled={isResponding}
          onClick={() =>
            window.open(`/shared?session_id=${sessionId}`, '_blank')
          }
        /> */}
        {isOpenned ? (
          <IconButton
            Icon={MdCallEnd}
            className='icon-red'
            bgcolor='red'
            onClick={closeVoiceChat}
          />
        ) : (
          <IconButton
            Icon={TbPhoneCall}
            className='icon-green'
            bgcolor='green'
            onClick={openVoiceChat}
          />
        )}
      </div>
      <Button
        size='sm'
        style={{ display: isOpenned ? 'flex' : 'none' }}
        onPress={openEvaluation}
      >
        评测结果
      </Button>
      <Modal
        scroll
        width='600px'
        aria-labelledby='modal-title'
        aria-describedby='modal-description'
        {...bindings}
      >
        <Modal.Header>
          <Text id='modal-title' size={18}>
            评测结果
          </Text>
        </Modal.Header>
        <Modal.Body>
          <Collapse.Group>
            {conversation.map(item => (
              <Collapse title={item.text} key={item.id}>
                <Text>
                  准确度评分：{formatPoint(item.evaluation['accuracy_score'])}
                </Text>
                <Text>
                  流畅度评分：{formatPoint(item.evaluation['fluency_score'])}
                </Text>
                <Text>
                  完整度评分：{formatPoint(item.evaluation['integrity_score'])}
                </Text>
                <Text>
                  标准度分：{formatPoint(item.evaluation['standard_score'])}
                </Text>
                <Text>总分：{formatPoint(item.evaluation['total_score'])}</Text>
              </Collapse>
            ))}
          </Collapse.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button auto flat color='primary' onPress={closeEvaluation}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CallView;
