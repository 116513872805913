import { useRef } from 'react';

const LongPressButton = ({
  disabled,
  onLongPressStart,
  onLongPressMove,
  onLongPressEnd,
}) => {
  const timerForLongPress = useRef(null);
  const isLongPress = useRef(false);
  const initialTouch = useRef(null);

  const _clearTimer = () => {
    if (timerForLongPress.current !== null) {
      clearTimeout(timerForLongPress.current);
      timerForLongPress.current = null;
    }
  };

  const onTouchStart = event => {
    if (disabled) {
      return;
    }
    isLongPress.current = false;
    if (event.changedTouches.length > 0) {
      initialTouch.current = event.changedTouches[0];
    }
    timerForLongPress.current = setTimeout(() => {
      isLongPress.current = true;
      onLongPressStart();
    }, 350);
  };

  const onTouchMove = event => {
    if (disabled) {
      return;
    }
    if (isLongPress.current) {
      onLongPressMove(event);
      return;
    }
    // 未长按判断滑动
    if (event.changedTouches.length > 0) {
      const touch = event.changedTouches[0];
      const x = touch.clientX - initialTouch.current.clientX;
      const y = touch.clientY - initialTouch.current.clientY;
      if (Math.abs(x) > 20 || Math.abs(y) > 20) {
        _clearTimer();
      }
    }
  };

  const onTouchEnd = () => {
    if (disabled) {
      return;
    }
    _clearTimer();
    if (isLongPress.current) {
      onLongPressEnd();
    }
  };

  return (
    <div
      className={disabled ? 'send-button send-button-disabled' : 'send-button'}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
    >
      按住说话
    </div>
  );
};

export default LongPressButton;
