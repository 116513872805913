var _stream = null;
var _context = null;
var _source = null;
var _node = null;
var _recorder = null;
var _connected = false;

export async function _checkAvailable() {
  if (_stream !== null) {
    if (_source === null && _node === null) {
      try {
        _createAudioProcessor();
      } catch (error) {
        throw error;
      }
    }
    return;
  }
  if (navigator.mediaDevices.getUserMedia === undefined) {
    navigator.mediaDevices.getUserMedia = function (constraints) {
      var getUserMedia =
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia ||
        navigator.msGetUserMedia ||
        navigator.oGetUserMedia;
      if (!getUserMedia) {
        return Promise.reject(
          new Error('getUserMedia is not implemented in this browser')
        );
      }
      return new Promise(function (resolve, reject) {
        getUserMedia.call(navigator, constraints, resolve, reject);
      });
    };
  }
  try {
    _stream = await navigator.mediaDevices.getUserMedia({
      audio: {
        channelCount: 1,
        echoCancellation: true,
      },
    });
  } catch (error) {
    throw new Error(error.message || '用户未授权录音或者不支持录音');
    // throw new Error('用户未授权录音或者不支持录音');
  }
  try {
    _createAudioProcessor();
  } catch (error) {
    throw error;
  }
}

export async function _startRecord(option) {
  try {
    await _checkAvailable();
    if (_recorder === null) {
      _recorder = new MediaRecorder(_stream);
    }
    _addAudioProcessor(option.onData);
    // 监听结束
    _recorder.onstop = () => {
      if (option.onData instanceof Function) {
        option.onData('', true);
      }
      _recorder.onstop = null;
    };
    _recorder.start();
  } catch (error) {
    throw error;
  }
}

export function _stopRecord() {
  _removeAudioProcessor();
  if (_recorder !== null) {
    _recorder.stop();
  }
}

export function _getRecorderState() {
  if (_recorder === null) {
    return 'unknow';
  }
  return _recorder.state;
}

export function _playAudio(audioBuffer, onPlayEnd) {
  if (_context === null) {
    onPlayEnd();
    return;
  }
  console.log('需要播放的音频的buffer流', audioBuffer);
  // 空音频
  console.log(audioBuffer, 'audioBufferaudioBuffer');
  if (audioBuffer.byteLength === 0) {
    console.log('空音频');
    onPlayEnd();
    return;
  }
  try {
    _context.decodeAudioData(audioBuffer, buffer => {
      let source = _context.createBufferSource();
      source.buffer = buffer;
      source.loop = false;
      source.connect(_context.destination);
      source.onended = () => {
        console.log('播放结束');
        onPlayEnd();
        source.disconnect(_context.destination);
        source.onended = null;
        source = null;
      };
      source.start(0);
    });
  } catch (error) {
    onPlayEnd();
  }
}

function _createAudioProcessor() {
  try {
    if (
      _checkNull(window.AudioContext) &&
      _checkNull(window.webkitAudioContext)
    ) {
      throw new Error('不支持录音');
    }
    _context = new (window.AudioContext || window.webkitAudioContext)({
      sampleRate: 16000,
    });
    _source = _context.createMediaStreamSource(_stream);
    _node = _context.createScriptProcessor(1024, 1, 1);
    _node.connect(_context.destination);
  } catch (error) {
    throw error;
  }
}

function _addAudioProcessor(onData) {
  if (_source === null && _node === null) {
    return;
  }
  if (_connected) {
    return;
  }
  _node.onaudioprocess = event => {
    const input = event.inputBuffer.getChannelData(0);
    const output = _floatTo16BitPCM(input);
    const buffer = output.buffer;
    const base64Data = _arrayBufferToBase64(buffer);
    if (onData instanceof Function) {
      onData(base64Data);
    }
  };
  _source.connect(_node);
  _connected = true;
}

function _removeAudioProcessor() {
  if (_source === null && _node === null) {
    return;
  }
  if (!_connected) {
    return;
  }
  _node.onaudioprocess = null;
  _source.disconnect(_node);
  _connected = false;
}

function _checkNull(object) {
  return object === undefined || object === null;
}
function _floatTo16BitPCM(input) {
  const bitDepth = 16;
  const bytesPerSample = bitDepth / 8;
  let offset = 0;
  let buffer = new ArrayBuffer(input.length * bytesPerSample);
  let output = new DataView(buffer);
  for (let i = 0; i < input.length; i++, offset += 2) {
    let s = Math.max(-1, Math.min(1, input[i]));
    output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
  }
  return output;
}
function _arrayBufferToBase64(buffer) {
  var binary = '';
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
}
