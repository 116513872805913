import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Col,
  Row,
  Spacer,
  Text,
  Button,
  Avatar,
  Modal,
  Input,
  Radio,
} from '@nextui-org/react';
import { getHostName } from '../utils/urlUtils';
import { post, postJson } from '../utils/util';

const User = ({ isLoggedIn, user, setUser, openToast }) => {
  const navigator = useNavigate();
  const [showEdit, setShowEdit] = useState(false);
  const [showBind, setShowBind] = useState(false);
  const [cutdown, setCutdown] = useState(60);
  const timer = useRef(null);
  const [isSended, setIsSended] = useState(false);
  const phoneInputRef = useRef(null);
  const codeInputRef = useRef(null);
  const nameInputRef = useRef(null);
  const [sexInput, setSexInput] = useState(null);
  const lock = useRef(false);

  const clearTimer = () => {
    if (timer.current !== null) {
      clearInterval(timer.current);
      timer.current = null;
      setIsSended(false);
      setCutdown(60);
    }
  };

  useEffect(() => {
    return () => {
      clearTimer();
    };
  }, []);

  if (!isLoggedIn || user === null) {
    return <div></div>;
  }

  const { phone, headimgurl, name, nickname, openid, sex } = user;
  const isBindedPhone = phone !== null && phone !== '';
  const isBindedWx = openid !== null && openid !== '';
  const bindWx = () => {
    navigator('/bindWx');
  };
  const openEdit = () => {
    setSexInput(user.sex);
    setShowEdit(true);
  };
  const onEditOpen = () => {
    setTimeout(() => {
      nameInputRef.current.value =
        user.name || user.nickname || user.phone.substring(7);
    }, 0);
  };
  const closeEdit = () => {
    setShowEdit(false);
    nameInputRef.current = null;
    setSexInput(null);
  };
  const openBind = () => {
    setShowBind(true);
  };
  const closeBind = () => {
    setShowBind(false);
    clearTimer();
  };
  const validateName = () => {
    if (!nameInputRef.current) {
      openToast('名称输入框未初始化', 'error');
      return false;
    }
    const text = nameInputRef.current.value;
    if (text === null || text === '') {
      openToast('请输入名称', 'error');
      return false;
    }
    return true;
  };
  const validateSex = () => {
    if (sexInput === null || sexInput === '') {
      openToast('请选择性别', 'error');
      return false;
    }
    return true;
  };
  const saveInfo = () => {
    if (lock.current) {
      return;
    }
    if (!validateName()) {
      return;
    }
    if (!validateSex()) {
      return;
    }
    lock.current = true;
    const id = user.id;
    const name = nameInputRef.current.value;
    const sex = sexInput;
    const scheme = window.location.protocol;
    const url = scheme + '//' + getHostName() + '/edit_user';

    postJson(url, {
      data: {
        id,
        name,
        sex,
      },
      success: _ => {
        closeEdit();
        openToast('编辑成功', 'success');
        const newUser = JSON.parse(JSON.stringify(user));
        newUser.name = name;
        newUser.sex = sex;
        localStorage.setItem('USERINFO', JSON.stringify(newUser));
        setUser(newUser);
      },
      fail: error => {
        openToast(error.message, 'error');
      },
      complete: () => {
        lock.current = false;
      },
    });
  };
  const validatePhone = () => {
    if (!phoneInputRef.current) {
      openToast('手机号输入框未初始化', 'error');
      return false;
    }
    const text = phoneInputRef.current.value;
    if (text === null || text === '') {
      openToast('请输入手机号', 'error');
      return false;
    }
    if (!/^1[3456789]\d{9}$/.test(text)) {
      openToast('手机号格式错误', 'error');
      return false;
    }
    return true;
  };
  const validateCode = () => {
    if (!codeInputRef.current) {
      openToast('验证码输入框未初始化', 'error');
      return false;
    }
    const text = codeInputRef.current.value;
    if (text === null || text === '') {
      openToast('请输入验证码', 'error');
      return false;
    }
    return true;
  };
  const getCode = async () => {
    if (lock.current) {
      return;
    }
    if (!validatePhone()) {
      return;
    }
    lock.current = true;
    const scheme = window.location.protocol;
    const url =
      scheme +
      '//' +
      getHostName() +
      '/send_sms_code?phone=' +
      phoneInputRef.current.value;
    post(url, {
      success: _ => {
        setIsSended(true);
        timer.current = setInterval(() => {
          setCutdown(cutdown => {
            if (cutdown === 1) {
              clearTimer();
            }
            return cutdown - 1;
          });
        }, 1000);
      },
      fail: error => {
        openToast(error.message, 'error');
      },
      complete: () => {
        lock.current = false;
      },
    });
  };
  const bindPhone = async () => {
    if (lock.current) {
      return;
    }
    if (!validatePhone()) {
      return;
    }
    if (!validateCode()) {
      return;
    }

    lock.current = true;
    const id = user.id;
    const phone = phoneInputRef.current.value;
    const code = codeInputRef.current.value;
    const scheme = window.location.protocol;
    const url = scheme + '//' + getHostName() + '/edit_user_mobile';
    postJson(url, {
      data: {
        id,
        phone,
        code,
      },
      success: _ => {
        closeBind();
        openToast('绑定成功', 'success');
        const newUser = JSON.parse(JSON.stringify(user));
        newUser.phone = phone;
        localStorage.setItem('USERINFO', JSON.stringify(newUser));
        setUser(newUser);
      },
      fail: error => {
        openToast(error.message, 'error');
      },
      complete: () => {
        lock.current = false;
      },
    });
  };

  return (
    <div className='user-wrapper'>
      <Spacer y={1}></Spacer>
      {headimgurl === null || headimgurl === '' ? (
        <Avatar
          className='usericon'
          size='md'
          color='primary'
          text={name || nickname || phone.substring(7)}
          textColor='white'
        />
      ) : (
        <Avatar className='usericon' size='md' src={headimgurl} />
      )}
      <Spacer y={2}></Spacer>
      <div className='user-content'>
        <Row align='center' justify='center' fluid={false}>
          <Text>名称：</Text>
          <Text>{name || nickname || `用户${phone.substring(7)}`}</Text>
        </Row>
        <Row align='center' justify='center'>
          <Text>性别：</Text>
          <Text>{sex === 0 ? '男' : sex === 1 ? '女' : '未设置'}</Text>
        </Row>
        <Row align='center' justify='center'>
          <Text>手机：</Text>
          <Text>
            {isBindedPhone
              ? `${phone.substring(0, 3) + '***' + phone.substring(7)}`
              : '未绑定'}
          </Text>
          <Spacer x={1}></Spacer>
          {isBindedPhone ? (
            <div className='text-button' onClick={openBind}>
              更换手机号
            </div>
          ) : (
            <div className='text-button' onClick={openBind}>
              绑定手机号
            </div>
          )}
        </Row>
        <Row align='center' justify='center'>
          <Text>微信：</Text>
          <Text>{isBindedWx ? '已绑定' : '未绑定'}</Text>
          <Spacer x={1}></Spacer>
          {isBindedWx ? (
            <div></div>
          ) : (
            <div className='text-button' onClick={bindWx}>
              绑定
            </div>
          )}
        </Row>
        <Spacer y={2}></Spacer>
      </div>
      <Button onPress={openEdit}>编辑信息</Button>
      <Modal key='info' open={showEdit} onOpen={onEditOpen}>
        <Modal.Header>
          <Text h2>编辑信息</Text>
        </Modal.Header>
        <Modal.Body>
          <Col>
            <Text>名称</Text>
            <Input
              aria-label='name'
              ref={nameInputRef}
              placeholder='请输入名称'
              maxLength={11}
            ></Input>
          </Col>
          <Col>
            <Text>性别</Text>
            <Radio.Group
              aria-label='sex'
              value={sexInput}
              orientation='horizontal'
              onChange={value => setSexInput(value)}
            >
              <Radio value={0}>男</Radio>
              <Radio value={1}>女</Radio>
            </Radio.Group>
          </Col>
        </Modal.Body>
        <Modal.Footer>
          <Button size='sm' onPress={closeEdit}>
            取消
          </Button>
          <Button size='sm' onPress={saveInfo}>
            确定
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal key='bind' open={showBind} preventClose={true}>
        <Modal.Header>
          <Text h2>绑定手机号</Text>
        </Modal.Header>
        <Modal.Body>
          <Col>
            <Text>手机号</Text>
            <Input
              ref={phoneInputRef}
              aria-label='phone'
              placeholder='请输入手机号'
              maxLength={11}
            ></Input>
          </Col>
          <Col>
            <Text>验证码</Text>
            <Row align='center'>
              <Input
                ref={codeInputRef}
                aria-label='code'
                placeholder='请输入验证码'
                maxLength={6}
              ></Input>
              <Spacer x={1}></Spacer>
              {isSended ? (
                <Text>{cutdown}s</Text>
              ) : (
                <Button size='xs' onPress={getCode}>
                  获取验证码
                </Button>
              )}
            </Row>
          </Col>
        </Modal.Body>
        <Modal.Footer>
          <Button size='sm' onPress={closeBind}>
            取消
          </Button>
          <Button size='sm' onPress={bindPhone}>
            确定
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default User;
