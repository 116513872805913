/**
 * src/pages/Settings.jsx
 *
 * created by Lynchee on 7/28/23
 */

// TODO: user can access this page only if isConnected.current and selectedCharacter

import React, { useState, useEffect } from 'react';
import Languages from '../components/Languages';
import MediaDevices from '../components/MediaDevices';
import Models from '../components/Models';
import Button from '@mui/material/Button';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import './styles.css';
import CommunicationMethod from '../components/CommunicationMethod';
import AdvancedOptions from '../components/AdvancedOptions';
import lz from 'lz-string';

const Settings = ({
  isMobile,
  commMethod,
  setCommMethod,
  preferredLanguage,
  setPreferredLanguage,
  selectedDevice,
  setSelectedDevice,
  selectedModel,
  setSelectedModel,
  isLoggedIn,
  token,
  setToken,
  useSearch,
  setUseSearch,
  useQuivr,
  setUseQuivr,
  quivrApiKey,
  setQuivrApiKey,
  quivrBrainId,
  setQuivrBrainId,
  useMultiOn,
  setUseMultiOn,
  useEchoCancellation,
  setUseEchoCancellation,
  send,
  openToast,
}) => {
  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);

  const handleSave = () => {
    if (saving) {
      return;
    }
    setSaving(true);
    const settings = {
      _commMethod: commMethod,
      _preferredLanguage: preferredLanguage,
      _selectedModel: selectedModel,
      _useSearch: useSearch,
      _useMultiOn: useMultiOn,
      _useEchoCancellation: useEchoCancellation,
    };
    localStorage.setItem('SETTINGS', JSON.stringify(settings));

    setCommMethod(commMethod);
    setPreferredLanguage(preferredLanguage);
    setSelectedDevice(selectedDevice || 'default');
    setSelectedModel(selectedModel);
    setUseSearch(useSearch);
    setUseMultiOn(useMultiOn);
    setUseEchoCancellation(useEchoCancellation);
    openToast('Save Successfully!', 'success');

    setTimeout(() => {
      navigate('/');
    }, 1000);
  };

  return (
    <div className='settings'>
      <h2 className='center'>Setting</h2>

      <CommunicationMethod
        commMethod={commMethod}
        setCommMethod={setCommMethod}
      />

      <Languages
        preferredLanguage={preferredLanguage}
        setPreferredLanguage={setPreferredLanguage}
      />

      <MediaDevices
        selectedDevice={selectedDevice}
        setSelectedDevice={setSelectedDevice}
      />

      <Models
        isMobile={isMobile}
        selectedModel={selectedModel}
        setSelectedModel={setSelectedModel}
      />

      <AdvancedOptions
        isLoggedIn={isLoggedIn}
        token={token}
        setToken={setToken}
        useSearch={useSearch}
        setUseSearch={setUseSearch}
        useQuivr={useQuivr}
        setUseQuivr={setUseQuivr}
        quivrApiKey={quivrApiKey}
        setQuivrApiKey={setQuivrApiKey}
        quivrBrainId={quivrBrainId}
        setQuivrBrainId={setQuivrBrainId}
        useMultiOn={useMultiOn}
        setUseMultiOn={setUseMultiOn}
        useEchoCancellation={useEchoCancellation}
        setUseEchoCancellation={setUseEchoCancellation}
        send={send}
      />
      <Button
        variant='contained'
        onClick={handleSave}
        fullWidth
        size='large'
        sx={{
          textTransform: 'none',
        }}
      >
        Save
      </Button>
    </div>
  );
};

export default Settings;
