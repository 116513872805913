import React, { useState, useRef, useEffect } from 'react';
import { getHostName } from '../utils/urlUtils';

const BindWx = () => {
  const isInit = useRef(false);
  const loaded = useRef(false);
  const [src, setSrc] = useState('');

  const getLoginUrl = async () => {
    const scheme = window.location.protocol;
    const url = scheme + '//' + getHostName() + '/login';

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 200) {
        const result = await response.json();
        if (result.code === 200) {
          const { url } = result.data || {};
          if (url !== undefined && url !== '') {
            loaded.current = true;
            setSrc(url);
          }
        } else {
          throw new Error(result.msg);
        }
      }
    } catch (error) {
      console.error('login', error);
    }
  };

  useEffect(() => {
    if (!isInit.current) {
      isInit.current = true;
      getLoginUrl();
    }
  }, []);

  if (!isInit.current || !loaded.current) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flex: '1 1',
          width: '100%',
        }}
      >
        <div>Loading...</div>
      </div>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flex: '1 1',
        width: '100%',
      }}
    >
      <iframe
        src={src}
        frameBorder='0'
        style={{
          width: '100%',
          height: '50vh',
        }}
      ></iframe>
    </div>
  );
};

export default BindWx;
