var worker = null;
var listeners = [];

const evaluateSpeech = params => {
  if (worker === null) {
    initializeWorker();
  }
  try {
    worker.postMessage({
      status: 'MESSAGE',
      params,
    });
  } catch (error) {
    console.log('send message to worker fail', error);
  }
};

const addListener = listener => {
  if (listener === null) {
    throw new Error('listener is null');
  }
  if (!(listener instanceof Function)) {
    throw new Error('listener is function');
  }
  if (listeners.indexOf(listener) > -1) {
    return;
  }
  listeners.push(listener);
};

const removeListener = listener => {
  if (listener === null) {
    throw new Error('listener is null');
  }
  if (!(listener instanceof Function)) {
    throw new Error('listener is function');
  }
  const index = listeners.indexOf(listener);
  if (index > -1) {
    listeners.splice(index, 1);
  }
};

const destroy = () => {
  if (worker) {
    worker.postMessage('CLOSE');
  }
};

const initializeWorker = () => {
  try {
    worker = new Worker('/worker/speechEvaluationWorker.js');
    // 监听事件
    worker.onmessage = event => {
      try {
        const { status = '', result } = JSON.parse(event.data);
        if (status === 'MESSAGE') {
          if (listeners.length > 0) {
            listeners.forEach(listener => {
              listener(result);
            });
          }
        }
        if (status === 'CLOSE') {
          worker.terminate();
          worker = null;
          listeners = [];
        }
      } catch (error) {
        console.log('worker onmessage error', error);
      }
    };
  } catch (error) {
    console.log(error);
  }
};

const downloadAudio = params => {
  const { audioList = [] } = params;
  const blob = new Blob(audioList, { type: 'audio/webm;codecs=opus' });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `录音_${new Date().getTime()}.wav`);
  link.click();
};

export default {
  evaluateSpeech,
  addListener,
  removeListener,
  destroy,
};
