import { useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import CryptoJs from 'crypto-js';
import useConversation from './useConversation.js';

const useXunFeiRecognition = (
  callActive,
  preferredLanguage,
  shouldPlayAudio,
  isConnected,
  audioSent,
  stopAudioPlayback,
  send,
  setTextAreaValue
) => {
  const APP_ID = '49939c7b';
  const API_KEY = '17c4ac9b72497e7fb815100ef2067f22';
  const API_SECRECT = 'ZTRjOTZlNThjN2JiMGY1YThhMWFlNjM5';
  const recognition = useRef(null);
  // 状态：uninit-待初始化 initting-初始化中 inited-初始化完成 destroyed-已销毁
  const status = useRef('uninit');
  // 连接websocket首次发送
  var isFirstFrame = useRef(false);
  let isRunning = useRef(false);
  const taskList = useRef([]);
  const recognizedText = useRef('');
  // 重连次数
  const tryCount = useRef(0);

  const initializeSpeechRecognition = async () => {
    if (status.current === 'uninit' || status.current === 'destroyed') {
      status.current = 'initting';
      const result = await _connect();
      // 初始化失败则无法使用
      if (!result.success) {
        status.current = 'destroyed';
        throw new Error(result.message);
      }
    }
  };

  const startListening = () => {};

  const recognize = async (audio, isEnd = false) => {
    const task = {
      audio: audio,
      end: isEnd,
    };
    taskList.current.push(task);
    if (isRunning.current) {
      return;
    }
    _runTask();
  };

  const stopListening = () => {};

  const closeRecognition = async () => {
    if (status.current === 'destroyed') {
      return;
    }

    status.current = 'destroyed';
    isRunning.current = false;
    taskList.current = [];
    recognizedText.current = '';

    if (
      recognition.current &&
      recognition.current.readyState === WebSocket.OPEN
    ) {
      recognition.current.close();
      while (recognition.current.readyState !== WebSocket.CLOSED) {
        await new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve();
          }, 50);
        });
      }
      recognition.current.onmessage = null;
      recognition.current = null;
    }
  };

  const _getAuthorization = (host, api, date) => {
    const requestLine = `GET ${api} HTTP/1.1`;
    const stringNeedSigned = `host: ${host}\ndate: ${date}\n${requestLine}`;
    const signature = CryptoJs.enc.Base64.stringify(
      CryptoJs.HmacSHA256(stringNeedSigned, API_SECRECT)
    );
    const stringNeedAuthed = `api_key="${API_KEY}", algorithm="hmac-sha256", headers="host date request-line", signature="${signature}"`;
    return CryptoJs.enc.Base64.stringify(
      CryptoJs.enc.Utf8.parse(stringNeedAuthed)
    );
  };

  const _connect = async () => {
    const isHttps = window.location.protocol === 'https:';
    const ws_scheme = isHttps ? 'wss' : 'ws';
    const host = 'iat-api.xfyun.cn';
    const api = '/v2/iat';
    const date = new Date().toUTCString();
    const authorization = _getAuthorization(host, api, date);
    const ws_path = `${ws_scheme}://${host}${api}?authorization=${authorization}&date=${date}&host=${host}`;
    const websocket = new WebSocket(ws_path);

    const result = await new Promise((resolve, reject) => {
      const result = {};
      // 监听异常
      websocket.onerror = (_, event) => {
        console.log('xunfei websocket error');
        // 连接失败
        if (status.current === 'initting') {
          status.current = 'uninit';
          result.success = false;
          result.message = '连接失败';
          resolve(result);
        }
      };

      websocket.onopen = () => {
        result.success = true;
        resolve(result);
      };
    });
    if (!result.success) {
      return result;
    }

    websocket.onmessage = event => {
      const response = JSON.parse(event.data);
      if (response.code === 0 && response.message === 'success') {
        const { result = {}, status } = response.data || {};
        const { ws = [] } = result;
        if (ws.length > 0) {
          const text = ws
            .map(item => {
              const { cw = [] } = item || {};
              if (cw.length === 0) {
                return '';
              }
              return cw.map(item => item.w || '').join('');
            })
            .join('');
          recognizedText.current += text;
        }
        if (status === 2) {
          const word = recognizedText.current;
          recognizedText.current = '';
          // 若识别为空则可能触发静音检测
          if (word === '') {
            useConversation.clearAudio();
            recognition.current.close();
            return;
          }
          useConversation.saveText(word);
          send(word);
          setTextAreaValue(prevState => prevState + `You>${word}\n\n`);
        }
      }
    };
    isFirstFrame.current = true;
    status.current = 'inited';
    recognition.current = websocket;
    return result;
  };

  const _runTask = async () => {
    isRunning.current = true;
    if (status.current === 'destroyed') {
      isRunning.current = false;
      taskList.current = [];
      return;
    }
    // 若正在关闭中则等待完全关闭
    if (recognition.current.readyState === WebSocket.CLOSING) {
      while (recognition.current.readyState === WebSocket.CLOSING) {
        await new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve();
          }, 50);
        });
      }
    }
    if (recognition.current.readyState === WebSocket.CLOSED) {
      const reconnect = await _tryReconnect();
      if (!reconnect.success) {
        await closeRecognition();
        return;
      }
    }
    if (taskList.current.length === 0) {
      isRunning.current = false;
      return;
    }
    var audioStatus = 1;
    const currentTask = taskList.current.splice(0, 1)[0];
    // 首帧
    if (isFirstFrame.current) {
      audioStatus = 0;
    }
    // 末帧
    if (currentTask.end) {
      audioStatus = 2;
    }
    var message = {
      data: {
        status: audioStatus,
        format: 'audio/L16;rate=16000',
        encoding: 'raw',
        audio: currentTask.audio,
      },
    };
    // 单次websocket通信首帧
    if (isFirstFrame.current) {
      isFirstFrame.current = false;
      message.common = {
        app_id: APP_ID,
      };
      message.business = {
        language: 'en_us',
        domain: 'iat',
        accent: 'mandarin',
      };
    }
    recognition.current.send(JSON.stringify(message));
    // 发送音频间隔40ms
    await new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve();
      }, 40);
    });
    _runTask();
  };

  const _tryReconnect = async () => {
    if (tryCount.current === 5) {
      return { success: false };
    }
    tryCount.current += 1;
    const reconnect = await _connect();
    if (!reconnect.success) {
      return await _tryReconnect();
    }
    tryCount.current = 0;
    return reconnect;
  };

  return {
    initializeSpeechRecognition,
    recognize,
    startListening,
    stopListening,
    closeRecognition,
  };
};

export default useXunFeiRecognition;
