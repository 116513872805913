/**
 * src/components/TextView/index.jsx
 * show chat log. User can send message and switch to CallView.
 *
 * created by Lynchee on 7/16/23
 */

import React, { useEffect, useRef, useState } from 'react';
import './style.css';
import {
  TbPower,
  TbPhoneCall,
  TbMicrophone,
  TbPlayerStopFilled,
  TbKeyboard,
  TbShare2,
} from 'react-icons/tb';
import IconButton from '../Common/IconButton';
import { MdVoiceChat } from 'react-icons/md';
// import Button from '../Common/Button';
import { useNavigate } from 'react-router-dom';
import Feedback from '../Feedback';
import { Button } from '@mui/material';
// utils
import { playAudios } from '../../utils/audioUtils';

const TextView = ({
  selectedCharacter,
  send,
  isPlaying,
  isThinking,
  isResponding,
  stopAudioPlayback,
  textAreaValue,
  setTextAreaValue,
  messageInput,
  setMessageInput,
  handleDisconnect,
  setIsCallView,
  useSearch,
  setUseSearch,
  callActive,
  startRecording,
  stopRecording,
  messageId,
  token,
  sessionId,
  isConnected,
  audioPlayer,
  audioQueue,
  audioContextRef,
  audioSourceNodeRef,
  setIsPlaying,
  playAudioFromNode,
  initializeSpeechRecognition,
  closeRecognition,
}) => {
  const navigate = useNavigate();
  const [keyboard, SetKeyboard] = useState(true);
  const chatWindowRef = useRef(null);
  const [talking, setTalking] = useState(false);
  const [isOpenning, setIsOpenning] = useState(true);
  const [isOpenned, setIsOpenned] = useState(false);
  const { initialize, setInitialize } = useState(true);

  useEffect(() => {
    var timer = setInterval(() => {
      if (isConnected.current) {
        clearInterval(timer);
        timer = null;
        setIsOpenning(false);
        setIsOpenned(true);
      }
    }, 100);
    return () => {
      if (timer !== null) {
        clearInterval(timer);
        timer = null;
      }
    };
  }, [isConnected]);

  // always show the latest chat log
  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [textAreaValue]);

  useEffect(() => {
    if (
      isThinking &&
      !textAreaValue.endsWith(`\n${selectedCharacter.name} is thinking...`)
    ) {
      setTextAreaValue(
        prevState => prevState + `\n${selectedCharacter.name} is thinking...`
      );
    } else if (
      !isThinking &&
      textAreaValue.endsWith(`\n${selectedCharacter.name} is thinking...`)
    ) {
      setTextAreaValue(prevState =>
        prevState.substring(
          0,
          prevState.length - `\n${selectedCharacter.name} is thinking...`.length
        )
      );
    }
  }, [isThinking, textAreaValue]);

  useEffect(() => {
    if (isPlaying && audioContextRef.current) {
      playAudios(
        audioContextRef,
        audioPlayer,
        audioQueue,
        setIsPlaying,
        playAudioFromNode,
        audioSourceNodeRef,
        initialize,
        setInitialize
      );
    }
  }, [isPlaying]);

  const handlePowerOffClick = async () => {
    // navigate('/');
    // handleDisconnect();
    if (talking) {
      await closeRecognition();
    }
    navigate('/');
  };

  // send message to server. stop audio if it's playing to interrupt character.
  const sendMessage = () => {
    // setTextAreaValue(prevState => prevState + `\nYou> ${messageInput}\n`);
    setTextAreaValue(prevState => prevState + `You> ${messageInput}\n\n`);
    send(messageInput);
    setMessageInput('');
    if (isPlaying) {
      stopAudioPlayback();
    }
  };

  const handleSendClick = () => {
    if (messageInput.trim() !== '') {
      sendMessage();
    }
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleSendClick();
    }
  };

  const handleInputChange = event => {
    setMessageInput(event.target.value);
  };

  const handlePushTalk = async () => {
    if (!talking) {
      await initializeSpeechRecognition();
      startRecording();
      setTalking(true);
      if (isPlaying) {
        stopAudioPlayback();
      }
    } else {
      stopRecording();
      await closeRecognition();
      setTalking(false);
    }
  };

  const handleKeyboardClick = () => {
    SetKeyboard(true);
  };

  const handleAudioClick = () => {
    SetKeyboard(false);
  };

  return (
    <div className='text-screen'>
      <audio ref={audioPlayer} className='audio-player'>
        <source src='' type='audio/mp3' />
      </audio>
      <div
        style={{
          display: isOpenning ? 'block' : 'none',
        }}
      >
        Connecting, Please wait a minute...
      </div>
      <textarea
        className='chat-window'
        readOnly
        draggable='false'
        ref={chatWindowRef}
        value={textAreaValue}
        style={{ display: isOpenned ? 'block' : 'none' }}
      ></textarea>

      {/* <Feedback messageId={messageId} token={token} /> */}

      <div
        className='input-container'
        style={{ display: isOpenned ? 'flex' : 'none' }}
      >
        <div className='message-input-container'>
          <input
            className='message-input'
            type='text'
            placeholder='Type your message'
            value={messageInput}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
          />
          <span className='focus-border'>
            <i></i>
          </span>
        </div>
        {!callActive.current && (
          <div>
            {keyboard ? (
              <IconButton
                Icon={MdVoiceChat}
                className='icon-blue'
                noMargin={true}
                onClick={handleAudioClick}
              />
            ) : (
              <IconButton
                Icon={TbKeyboard}
                className='icon-blue'
                noMargin={true}
                onClick={handleKeyboardClick}
              />
            )}
          </div>
        )}
      </div>

      {!callActive.current && !keyboard ? (
        talking ? (
          <IconButton
            Icon={TbPlayerStopFilled}
            className='recording-animation'
            bgcolor='red'
            onClick={handlePushTalk}
          />
        ) : (
          <IconButton
            Icon={TbMicrophone}
            className='icon-blue'
            bgcolor='default'
            onClick={handlePushTalk}
          />
        )
      ) : (
        // <Button onClick={handleSendClick} name='Send Message' />
        <div style={{ display: isOpenned ? 'block' : 'none' }}>
          <Button variant='contained' onClick={handleSendClick}>
            <div>Send Message</div>
          </Button>
        </div>
      )}

      <div className='options-container'>
        <IconButton
          Icon={TbPower}
          className='icon-red'
          onClick={handlePowerOffClick}
        />
        {/* <IconButton
          Icon={TbPhoneCall}
          className='icon-blue'
          onClick={() => setIsCallView(true)}
          disabled={talking.current}
        /> */}
        {/* <IconButton
          Icon={TbShare2}
          disabled={isResponding}
          onClick={() =>
            window.open(`/shared?session_id=${sessionId}`, '_blank')
          }
        /> */}
      </div>
    </div>
  );
};

export default TextView;
