import { v4 as uuidv4 } from 'uuid';
import useSpeechEvaluation from './useSpeechEvaluation.js';

var conversation = [];

function createConversation() {
  conversation = [];
}

function getConversation() {
  return conversation;
}

function createMessage() {
  const message = {
    id: uuidv4(),
    audioList: [],
    audioBase64List: [],
    text: null,
    evaluation: null,
  };
  conversation.push(message);
}

function saveAudio(audio, audioBase64) {
  if (conversation.length === 0) {
    return;
  }
  const message = conversation[conversation.length - 1];
  message.audioList.push(audio);
  message.audioBase64List.push(audioBase64);
}

function clearAudio() {
  if (conversation.length === 0) {
    return;
  }
  const message = conversation[conversation.length - 1];
  message.audioList = [];
  message.audioBase64List = [];
}

function saveText(text) {
  if (conversation.length === 0) {
    return;
  }
  const message = conversation[conversation.length - 1];
  message.text = text;
  _evaluate(message);
}

function _evaluate(message) {
  const callback = result => {
    _evaluateHandler(result, message, callback);
  };
  const params = JSON.parse(JSON.stringify(message));
  useSpeechEvaluation.addListener(callback);
  useSpeechEvaluation.evaluateSpeech(params);
}

function _evaluateHandler(result, message, listener) {
  try {
    const xmlString = atob(result);
    const json = window.parser.parse(xmlString, {
      attributeNamePrefix: '',
      ignoreAttributes: false,
    });
    if (json['xml_result'] !== null) {
      const xmlResult = json['xml_result'];
      if (xmlResult['read_sentence'] !== null) {
        const readSentence = xmlResult['read_sentence'];
        if (readSentence['rec_paper'] !== null) {
          const recPaper = readSentence['rec_paper'];
          if (recPaper['read_chapter'] !== null) {
            message.evaluation = recPaper['read_chapter'];
          }
        }
      }
    }
  } catch (error) {
    console.log('_evaluateHandler error', error);
  } finally {
    useSpeechEvaluation.removeListener(listener);
  }
}

export default {
  createConversation,
  getConversation,
  createMessage,
  saveAudio,
  clearAudio,
  saveText,
};
