import { useEffect, useState, useRef } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import {
  _connect,
  _disconnect,
  _send,
  _handleAnswer,
  _createMessage,
  _runPlay,
} from './websocket';
import {
  _checkAvailable,
  _startRecord,
  _stopRecord,
  _getRecorderState,
} from './media';
import { _addListener, _evaluate } from './evaluate';
import LongPressButton from './longPressButton';
// import { getHostName } from '../utils/urlUtils';

import shengbo_pink from '../assets/images/shengbo_pink.png';
import fasong_active from '../assets/images/fasong_active.png';
import fasong_disabled from '../assets/images/fasong_disabled.png';
import yuying_333 from '../assets/images/yuying_333.png';
import yuying_999 from '../assets/images/yuying_999.png';

import './mobile.css';

// const character = {
//   characterId: 'li_bai',
//   imageUrl: 'https://shenmo-statics.oss-cn-beijing.aliyuncs.com/li_bai.jpg',
// };

const Mobile = ({ openToast }) => {
  const [searchParams] = useSearchParams();
  const searchParams1 = new URLSearchParams(window.location.search);
  // console.log(sceneId);
  // console.log(scIndex);
  const character = useRef(null);
  // const sceneIndex = useRef(0);
  const [sceneIndex, setSceneIndex] = useState(
    Number(searchParams1.get('index')) || 0
  );
  const sceneList = useRef([]);
  // 0-未初始化 1-初始化中 2-初始化结束
  const initState = useRef(0);
  const [talkShow, setTalkShow] = useState(false);
  const [init, setInit] = useState(false);
  const [sayIng, setSayIng] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [showRecord, setShowRecord] = useState(false);
  const [messageList, setMessageList] = useState([]);
  const messageListTemp = useRef(null);
  const [active, setActive] = useState(false);
  var originalSetItem = localStorage.setItem;
  localStorage.setItem = function (key, newValue) {
    var setItemEvent = new Event('setItemEvent');
    setItemEvent.newValue = newValue;
    window.dispatchEvent(setItemEvent);
    originalSetItem.apply(this, arguments);
  };
  window.addEventListener('setItemEvent', function (e) {
    // alert(e.newValue);
    if (e.newValue === true && !sayIng) {
      setSayIng(true);
    }
    // console.log(e, 'eee');
    // console.log(e.newValue, 'eee');
  });
  const [scrollToBottom, setScrollToBottom] = useState(false);

  useEffect(() => {
    if (scrollToBottom) {
      const box = document.getElementById('message-box');
      requestAnimationFrame(() => {
        box.scrollTo({ top: box.scrollHeight, behavior: 'smooth' });
      });
      setScrollToBottom(false);
    }
  }, [scrollToBottom]);

  const handleScrollToBottom = () => {
    setScrollToBottom(true);
  };
  // 是否取消发送
  const isCancel = useRef(false);
  const getScene = async () => {
    try {
      const url = 'https://api.demo.shenmo-ai.net/jty/scene_list';
      const headers = {
        'Content-Type': 'application/json',
      };
      const response = await fetch(url, {
        method: 'GET',
        headers: headers,
      });
      if (response.status < 200 || response.status >= 300) {
        throw new Error('222');
      }
      const scene = await response.json();
      var sceneArr = scene.data;
      sceneList.current = sceneArr;
      console.log(sceneList.current, 'sceneList');
    } catch (error) {
      throw error;
    }
  };
  const getcharacterById = async () => {
    try {
      const url = 'https://api.demo.shenmo-ai.net/character_info';
      const headers = {
        'Content-Type': 'application/json',
      };
      const response = await fetch(url, {
        method: 'GET',
        headers: headers,
      });
      if (response.status < 200 || response.status >= 300) {
        throw new Error('角色获取失败，初始化失败');
      }
      const characterList = await response.json();
      character.current = characterList.data;
      console.log(characterList.data, '123');
      // for (let i = 0; i < characterList.data.length; i++) {
      //   if (characterList.data[i].character_id === character_id) {
      //     character.current = characterList.data[i];
      //     console.log(character.current, 'character.current');
      //     break;
      //   }
      // }
      if (character.current === null) {
        throw new Error('角色获取失败，初始化失败');
      }
      // 设置标题为角色名
      document.title = character.current.name;
    } catch (error) {
      throw error;
    }
  };
  const initPage1 = async () => {
    console.log('12322');
  };
  const initPage = async scene => {
    // 标题置空
    document.title = '';
    document.addEventListener('contextmenu', event => {
      event.preventDefault();
    });
    try {
      await _checkAvailable();
    } catch (error) {
      initPageEnd();
      openToast(error.message, 'error');
    }
    try {
      await getScene();
    } catch (error) {
      console.log('error', error);
    }
    try {
      await getcharacterById();
      const urlParams = getUrlParams();
      const urlOptions = {
        ...urlParams,
        character_id: character.current.character_id,
      };
      if (!urlOptions.scene_id) {
        urlOptions.scene_id = sceneList.current[0].id;
      }
      _connect(urlOptions, {
        onConnected: () => {
          initPageEnd();
          messageListTemp.current = [];
          setMessageList([]);
        },
        onAnswer: event => {
          _handleAnswer(event, {
            onData: updateMessageList,
            onEnd: () => {
              setDisabled(false);
              setSayIng(false);
              console.log('说完了');
            },
          });
        },
        onClosed: (code, reason) => {
          initPageEnd();
          setDisabled(true);
          setSayIng(true);
          if (code !== 1000) {
            openToast(`${reason}，请刷新页面再试`, 'error');
            // openToast('连接断开,即将刷新页面并重新连接', 'error');
            // setTimeout(function () {
            //   window.location.replace(window.location.href);
            // }, 3000);
          }
        },
      });
    } catch (error) {
      console.log('websocket connect error', error);
      initPageEnd();
      openToast('初始化失败，请刷新页面再试1', 'error');
    }
  };

  const getUrlParams = () => {
    const urlParams = {};
    const keys = searchParams.keys();
    let end = false;
    while (!end) {
      const next = keys.next();
      // 结束
      if (!next.done) {
        const key = next.value;
        urlParams[key] = searchParams.get(key);
      } else {
        end = true;
      }
    }
    return urlParams;
  };

  const initPageEnd = () => {
    console.log('开始2说话');
    initState.current = 2;
    setInit(true);
  };
  // const originalSetItem = localStorage.setItem;
  // localStorage.setItem = function (key, newValue) {
  //   const setItemEvent = new Event('setItemEvent');
  //   setItemEvent[key] = newValue;
  //   window.dispatchEvent(setItemEvent);
  //   originalSetItem.apply(this, [key, newValue]);
  // };
  // // 添加事件监听器
  // function handleSetItemEvent(event) {
  //   console.log('监听到本地存储发生变化了：', event);
  //   console.log('Key:', Object.keys(event)[0]);
  //   console.log('Value:', event[Object.keys(event)[0]]);
  // }
  // window.addEventListener('setItemEvent', handleSetItemEvent);
  // // 当不再需要时，移除事件监听器
  // window.removeEventListener('setItemEvent', handleSetItemEvent);
  const updateMessageList = (message, isInsert = true) => {
    const list = JSON.parse(JSON.stringify(messageListTemp.current));
    if (isInsert) {
      list.push(message);
    } else {
      let lastIndex = list.length - 1;
      while (lastIndex >= 0) {
        const lastMessage = list[lastIndex];
        if (lastMessage.id === message.id) {
          break;
        }
      }
      if (lastIndex >= 0) {
        list.splice(lastIndex, 1, message);
      }
    }
    messageListTemp.current = list;
    setMessageList(list);
    handleScrollToBottom();
    setTimeout(() => {
      const dom = document.querySelector('#message-list');
      if (dom !== null) {
        const distance = dom.scrollHeight - dom.clientHeight;
        dom.scroll(0, distance);
      }
    }, 300);
  };
  const onLongPressStart = () => {
    try {
      setShowRecord(true);
      setActive(true);
      isCancel.current = false;
      _startRecord({
        onData: _evaluate,
      });
      _addListener({
        onAnswer: result => {
          // 静音检测（可能会有返回值）
          if (_getRecorderState() === 'recording') {
            _stopRecord();
            setShowRecord(false);
            setActive(false);
            openToast('长时间不操作', 'error');
            return;
          }
          if (!isCancel.current) {
            console.log('shuohuazhong');
            setDisabled(true);
            // setSayIng(true);
            const text = result.data;
            const message = _createMessage(true);
            message.text = text;
            updateMessageList(message);
            _send(text);
          }
        },
        onClosed: (code, reason) => {
          if (!isCancel.current && code !== 1000) {
            _stopRecord();
            setShowRecord(false);
            setActive(false);
            openToast(reason, 'error');
          }
        },
      });
    } catch (error) {
      console.log(error);
      openToast(error.message, 'error');
    }
  };

  const onLongPressMove = event => {
    if (event.changedTouches.length > 0) {
      const dom = document.querySelector('#record-button');
      if (dom !== null) {
        const { top, right, bottom, left } = dom.getBoundingClientRect();
        const { clientX, clientY } = event.changedTouches[0];
        const isXIn = clientX > left && clientX < right;
        const isYIn = clientY > top && clientY < bottom;
        console.log(isXIn, isYIn);
        if (isXIn && isYIn) {
          setActive(true);
        } else {
          setActive(false);
        }
      }
    }
  };

  const onLongPressCancal = () => {
    onLongPressEnd(1);
    // isCancel.current = !active;
    // _stopRecord();
  };
  const onLongPressEnd = type => {
    if (type) {
      isCancel.current = true;
    } else {
      isCancel.current = false;
    }
    setShowRecord(false);
    // isCancel.current = !active;
    _stopRecord();
  };
  // useEffect(() => {
  //   document.addEventListener('keydown', event => {
  //     if (event.key === ' ') {
  //       console.log(showRecord, 'showRecord');
  //       console.log(sayIng, 'sayIng');
  //       if (!showRecord && !sayIng) {
  //         onLongPressStart();
  //       }
  //       if (showRecord && !sayIng) {
  //         onLongPressEnd();
  //       }
  //     }
  //   });

  //   return () => {
  //     document.removeEventListener('keydown', () => {});
  //   };
  // }, [showRecord, sayIng]);
  const selectSubject = (item, index) => {
    console.log(item, index);
    // if (index !== 0) {
    window.location.replace(
      window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        '?scene_id=' +
        item.id +
        '&index=' +
        index
    );
    // } else {
    //   window.location.replace(
    //     window.location.protocol +
    //       '//' +
    //       window.location.host +
    //       window.location.pathname
    //   );
    // }
    // setSceneIndex(index);
    // setTalkShow(false);
    // console.log(setSceneIndex, 'sceneIndex.current');
    // _disconnect(1000, 'End');
    // const urlParams = getUrlParams();
    // const urlOptions = {
    //   ...urlParams,
    //   character_id: character.current.character_id,
    //   scene_id: item.id,
    // };
    // _connect(urlOptions, {
    //   onConnected: () => {
    //     initPageEnd();
    //     messageListTemp.current = [];
    //     setMessageList([]);
    //   },
    //   onAnswer: event => {
    //     _handleAnswer(event, {
    //       onData: updateMessageList,
    //       onEnd: () => {
    //         setDisabled(false);
    //         setSayIng(false);
    //         console.log('说完了');
    //       },
    //     });
    //   },
    //   onClosed: (code, reason) => {
    //     initPageEnd();
    //     setDisabled(true);
    //     setSayIng(true);
    //     if (code !== 1000) {
    //       openToast(`${reason}，请刷新页面再试`, 'error');
    //     }
    //   },
    // });
  };
  const handleShow = () => {
    setTalkShow(true);
  };
  const handleHide = () => {
    setTalkShow(false);
  };
  useEffect(() => {
    if (initState.current === 0) {
      initState.current = 1;
      initPage();
    }
    return () => {
      _disconnect(1000, 'End');
    };
  }, []);
  return (
    <div className='mobile-page'>
      <div className='chat-bg1'>
        <img
          className='bg'
          src='https://statics.shenmo-ai.com/sales/admin/1724741172761379809818golder.jpg'
        />
        <div className='bgobc'></div>
        <div className='content'>
          <div className='left-cont'>
            {character.current && !sayIng && (
              <img
                className='left-people'
                src={character.current.still_image}
              />
            )}
            {character.current && sayIng && (
              <img
                className='left-people'
                src={character.current.motion_image}
              />
            )}
            {/* <image v-show="!sayIng && still_image" class="left-people" :src="still_image" mode="widthFix"></image>
          <image v-show="sayIng && motion_image" class="left-people" :src="motion_image" mode="widthFix"></image> */}
            <img
              className='left-wu'
              src='https://statics.shenmo-ai.com/sales/admin/1724137199169420540395wu.png'
              mode='widthFix'
            ></img>
            <img
              className='left-foot'
              src='https://statics.shenmo-ai.com/sales/admin/1724137407438205770922foot.png'
              mode='widthFix'
            ></img>
          </div>
          <div className='right-cont'>
            <img
              className='sum-right'
              mode='widthFix'
              src='https://statics.shenmo-ai.com/sales/admin/1724139074784423612618top-sum.png'
              alt=''
            />
            <div
              className='message-box'
              id='message-box'
              style={{
                overflowY: 'auto',
                scrollbarWidth: 'none',
              }}
            >
              <div className='message-list' id='message-list'>
                {messageList.map((message, index) => (
                  <div className='message-boxer' key={message.id + '_' + index}>
                    <img
                      className='ai-avatar'
                      style={{
                        display: message.speaker === 'ai' ? 'block' : 'none',
                      }}
                      src='https://statics.shenmo-ai.com/sales/admin/1724205251731360792534aiAvatar.png'
                      mode='widthFix'
                    ></img>
                    {/* <img
                      className={
                        message.speaker === 'user' ? 'user-avatar' : 'ai-avatar'
                      }
                      mode='widthFix'
                      src={
                        message.speaker === 'ai'
                          ? 'https://statics.shenmo-ai.com/sales/admin/1724205251731360792534aiAvatar.png'
                          : 'https://statics.shenmo-ai.com/sales/admin/1724207454565344477542userAvatar.png'
                      }
                      alt='speaker icon'
                    /> */}
                    <div className={`message-item ${message.speaker}`}>
                      {message.text}
                    </div>
                    <img
                      className='user-avatar'
                      style={{
                        display: message.speaker === 'user' ? 'block' : 'none',
                      }}
                      src='https://statics.shenmo-ai.com/sales/admin/1724207454565344477542userAvatar.png'
                      mode='widthFix'
                    ></img>
                  </div>
                ))}
              </div>
            </div>
            <LongPressButton
              disabled={disabled}
              showRecord={showRecord}
              sayIng={sayIng}
              onLongPressStart={onLongPressStart}
              onLongPressCancal={onLongPressCancal}
              onLongPressMove={onLongPressMove}
              onLongPressEnd={onLongPressEnd}
            />
            <img
              onClick={handleShow}
              style={{ display: !talkShow ? 'flex' : 'none' }}
              className='talk-bottom'
              mode='widthFix'
              src='https://statics.shenmo-ai.com/sales/admin/1724382042313523636023taber.png'
              alt=''
            />
            {talkShow && (
              <div className='mask-talk'>
                <div className='mask-talk-box'>
                  {sceneList.current.map((item, index) => (
                    <div
                      className='talk-list'
                      key={index}
                      onClick={() => selectSubject(item, index)}
                    >
                      {/* <p>{item.id}</p> */}
                      {sceneIndex !== index && (
                        <img
                          src='https://statics.shenmo-ai.com/sales/admin/1724393406979106489933talk-default.png'
                          alt=''
                          className='talk-img'
                        />
                      )}
                      {sceneIndex === index && (
                        <img
                          src='https://statics.shenmo-ai.com/sales/admin/1724394510276157318667talk-active.png'
                          alt=''
                          className='talk-img'
                        />
                      )}
                      {sceneIndex !== index && <p>{item.title}</p>}
                      {sceneIndex === index && (
                        <p style={{ color: 'rgba(255, 255, 255, 1)' }}>
                          {item.title}
                        </p>
                      )}
                    </div>
                  ))}
                  <img
                    onClick={handleHide}
                    style={{ display: talkShow ? 'flex' : 'none' }}
                    className='close-talk'
                    mode='widthFix'
                    src='https://statics.shenmo-ai.com/sales/admin/1724393743442266213150close-talk.png'
                    alt=''
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {/* {character.current && !sayIng && (
          <img src={character.current.still_image} />
        )}
        {character.current && sayIng && (
          <img src={character.current.motion_image} />
        )} */}
      </div>
      {/* <div className='chat-container'>
        <div className='prologue'>
          {character.current ? (
            <div className='prologue-name'>
              {character.current.name}
              <div className='prologue-slogan'>{character.current.slogan}</div>
            </div>
          ) : null}
        </div>
        <div className='message-list' id='message-list'>
          {messageList.map((message, index) => (
            <div
              className={`message-item ${message.speaker}`}
              key={message.id + '_' + index}
            >
              {message.text}
            </div>
          ))}
        </div>
        <LongPressButton
          disabled={disabled}
          onLongPressStart={onLongPressStart}
          onLongPressMove={onLongPressMove}
          onLongPressEnd={onLongPressEnd}
        />
        <div className='bottom-safe-area'></div>
      </div> */}
      {/* <div
        className='record-container'
        style={{ display: showRecord ? 'flex' : 'none' }}
      >
        <div className='record-icon'>
          <img src={shengbo_pink} />
        </div>
        <div className='record-tip'>{active ? '松开发送' : '松开取消'}</div>
        <div className='record-button bottom-safe-area' id='record-button'>
          <img
            src={fasong_active}
            style={{ display: active ? 'block' : 'none' }}
          />
          <img
            src={fasong_disabled}
            style={{ display: active ? 'none' : 'block' }}
          />
          <div className='shengbo'>
            <img
              src={yuying_333}
              style={{ display: active ? 'block' : 'none' }}
            />
            <img
              src={yuying_999}
              style={{ display: active ? 'none' : 'block' }}
            />
          </div>
        </div>
      </div> */}
      {init ? null : (
        <div className='loading-container'>
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default Mobile;
