import { useEffect, useState, useRef } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import {
  _connect,
  _disconnect,
  _send,
  _handleAnswer,
  _createMessage,
} from './websocket';
import {
  _checkAvailable,
  _startRecord,
  _stopRecord,
  _getRecorderState,
} from './media';
import { _addListener, _evaluate } from './evaluate';
import LongPressButton from './longPressButton';

import shengbo_pink from '../assets/images/shengbo_pink.png';
import fasong_active from '../assets/images/fasong_active.png';
import fasong_disabled from '../assets/images/fasong_disabled.png';
import yuying_333 from '../assets/images/yuying_333.png';
import yuying_999 from '../assets/images/yuying_999.png';

import './libai.css';

const character = {
  characterId: 'li_bai',
  imageUrl: 'https://shenmo-statics.oss-cn-beijing.aliyuncs.com/li_bai.jpg',
};

const Libai = ({ openToast }) => {
  // 0-未初始化 1-初始化中 2-初始化结束
  const initState = useRef(0);
  const [init, setInit] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [showRecord, setShowRecord] = useState(false);
  const [messageList, setMessageList] = useState([]);
  const messageListTemp = useRef(null);
  const [active, setActive] = useState(false);
  // 是否取消发送
  const isCancel = useRef(false);

  const initPage = async () => {
    document.addEventListener('contextmenu', event => {
      event.preventDefault();
    });
    try {
      await _checkAvailable();
    } catch (error) {
      initPageEnd();
      openToast(error.message, 'error');
    }
    try {
      _connect(character.characterId, {
        onConnected: () => {
          initPageEnd();
          messageListTemp.current = [];
          setMessageList([]);
        },
        onAnswer: event => {
          _handleAnswer(event, {
            onData: updateMessageList,
            onEnd: () => {
              setDisabled(false);
            },
          });
        },
        onClosed: (code, reason) => {
          initPageEnd();
          setDisabled(true);
          if (code !== 1000) {
            openToast(`${reason}，请刷新页面再试`, 'error');
          }
        },
      });
    } catch (error) {
      console.log('websocket connect error', error);
      initPageEnd();
      openToast('初始化失败，请刷新页面再试', 'error');
    }
  };

  const initPageEnd = () => {
    initState.current = 2;
    setInit(true);
  };

  const updateMessageList = (message, isInsert = true) => {
    const list = JSON.parse(JSON.stringify(messageListTemp.current));
    if (isInsert) {
      list.push(message);
    } else {
      let lastIndex = list.length - 1;
      while (lastIndex >= 0) {
        const lastMessage = list[lastIndex];
        if (lastMessage.id === message.id) {
          break;
        }
      }
      if (lastIndex >= 0) {
        list.splice(lastIndex, 1, message);
      }
    }
    messageListTemp.current = list;
    setMessageList(list);
    setTimeout(() => {
      const dom = document.querySelector('#message-list');
      if (dom !== null) {
        const distance = dom.scrollHeight - dom.clientHeight;
        dom.scroll(0, distance);
      }
    }, 300);
  };

  const onLongPressStart = () => {
    try {
      setShowRecord(true);
      setActive(true);
      isCancel.current = false;
      _startRecord({
        onData: _evaluate,
      });
      _addListener({
        onAnswer: result => {
          // 静音检测（可能会有返回值）
          if (_getRecorderState() === 'recording') {
            _stopRecord();
            setShowRecord(false);
            setActive(false);
            openToast('长时间不操作', 'error');
            return;
          }
          if (!isCancel.current) {
            setDisabled(true);
            const text = result.data;
            const message = _createMessage(true);
            message.text = text;
            updateMessageList(message);
            _send(text);
          }
        },
        onClosed: (code, reason) => {
          if (!isCancel.current && code !== 1000) {
            _stopRecord();
            setShowRecord(false);
            setActive(false);
            openToast(reason, 'error');
          }
        },
      });
    } catch (error) {
      console.log(error);
      openToast(error.message, 'error');
    }
  };

  const onLongPressMove = event => {
    if (event.changedTouches.length > 0) {
      const dom = document.querySelector('#record-button');
      if (dom !== null) {
        const { top, right, bottom, left } = dom.getBoundingClientRect();
        const { clientX, clientY } = event.changedTouches[0];
        const isXIn = clientX > left && clientX < right;
        const isYIn = clientY > top && clientY < bottom;
        console.log(isXIn, isYIn);
        if (isXIn && isYIn) {
          setActive(true);
        } else {
          setActive(false);
        }
      }
    }
  };
  const onLongPressEnd = () => {
    setShowRecord(false);
    isCancel.current = !active;
    _stopRecord();
  };

  useEffect(() => {
    if (initState.current === 0) {
      initState.current = 1;
      initPage();
    }
    return () => {
      _disconnect(1000, 'End');
    };
  }, []);

  return (
    <div className='mobile-page'>
      <div className='chat-bg'>
        <img src={character.imageUrl} loading='lazy' />
      </div>
      <div className='chat-container'>
        <div className='message-list' id='message-list'>
          {messageList.map((message, index) => (
            <div
              className={`message-item ${message.speaker}`}
              key={message.id + '_' + index}
            >
              {message.text}
            </div>
          ))}
        </div>
        <LongPressButton
          disabled={disabled}
          onLongPressStart={onLongPressStart}
          onLongPressMove={onLongPressMove}
          onLongPressEnd={onLongPressEnd}
        />
        <div className='bottom-safe-area'></div>
      </div>
      <div
        className='record-container'
        style={{ display: showRecord ? 'flex' : 'none' }}
      >
        <div className='record-icon'>
          <img src={shengbo_pink} />
        </div>
        <div className='record-tip'>{active ? '松开发送' : '松开取消'}</div>
        <div className='record-button bottom-safe-area' id='record-button'>
          <img
            src={fasong_active}
            style={{ display: active ? 'block' : 'none' }}
          />
          <img
            src={fasong_disabled}
            style={{ display: active ? 'none' : 'block' }}
          />
          <div className='shengbo'>
            <img
              src={yuying_333}
              style={{ display: active ? 'block' : 'none' }}
            />
            <img
              src={yuying_999}
              style={{ display: active ? 'none' : 'block' }}
            />
          </div>
        </div>
      </div>
      {init ? null : (
        <div className='loading-container'>
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default Libai;
