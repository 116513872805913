const formatData = (data, isJson = false) => {
  if (isJson) {
    return JSON.stringify(data);
  }
  var formatedStr = '';
  Object.keys(data).forEach(key => {
    const value = data[key];
    formatedStr +=
      '&' +
      key +
      '=' +
      encodeURIComponent(
        typeof value === 'object' ? JSON.stringify(value) : value
      );
  });
  if (formatedStr !== '') {
    formatedStr = formatedStr.replace(/^&/, '');
  }
  return formatedStr;
};

const http = async _ => {
  const { url, option, success, fail, complete } = _;
  try {
    const response = await fetch(url, option);
    if (response.status === 200) {
      const result = await response.json();
      if (result.code === 200) {
        if (success !== null && success instanceof Function) {
          success(result.data);
        }
      } else {
        throw new Error(result.msg);
      }
    } else {
      throw new Error(response.text);
    }
  } catch (error) {
    if (fail !== null && fail instanceof Function) {
      fail(error);
    }
  } finally {
    if (complete !== null && complete instanceof Function) {
      complete();
    }
  }
};

const get = (url, options) => {
  const {
    data = {},
    headers = {},
    success,
    fail = () => {},
    complete = () => {},
  } = options || {};

  url += formatData(data);
  const token = localStorage.getItem('TOKEN');
  if (token !== null && token !== '') {
    headers['Authorization'] = 'Bearer ' + token;
  }
  headers['Content-Type'] = 'application/json';
  const option = {
    method: 'GET',
    headers,
  };

  http({
    url,
    option,
    success,
    fail,
    complete,
  });
};

const post = (url, options) => {
  const {
    data = {},
    headers = {},
    success,
    fail = () => {},
    complete = () => {},
  } = options || {};

  const body = formatData(data);
  const token = localStorage.getItem('TOKEN');
  if (token !== null && token !== '') {
    headers['Authorization'] = 'Bearer ' + token;
  }
  headers['Content-Type'] = 'application/json';
  const option = {
    method: 'POST',
    body,
    headers,
  };

  http({
    url,
    option,
    success,
    fail,
    complete,
  });
};

const postJson = (url, options) => {
  const {
    data = {},
    headers = {},
    success,
    fail = () => {},
    complete = () => {},
  } = options || {};

  const body = formatData(data, true);
  const token = localStorage.getItem('TOKEN');
  if (token !== null && token !== '') {
    headers['Authorization'] = 'Bearer ' + token;
  }
  headers['Content-Type'] = 'application/json';
  const option = {
    method: 'POST',
    body,
    headers,
  };

  http({
    url,
    option,
    success,
    fail,
    complete,
  });
};

export { get, post, postJson };
