import { useEffect, useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import {
  _connect,
  _disconnect,
  _send,
  _handleAnswer,
  _createMessage,
  _runPlay,
} from './websocket';
import {
  _checkAvailable,
  _startRecord,
  _stopRecord,
  _getRecorderState,
} from './media';
import { _addListener, _evaluate } from './evaluate';
import LongPressButton from './longPressButton';
// import { getHostName } from '../utils/urlUtils';

import shengbo_pink from '../assets/images/shengbo_pink.png';
import fasong_active from '../assets/images/fasong_active.png';
import fasong_disabled from '../assets/images/fasong_disabled.png';
import yuying_333 from '../assets/images/yuying_333.png';
import yuying_999 from '../assets/images/yuying_999.png';

import './mobile.css';

// const character = {
//   characterId: 'li_bai',
//   imageUrl: 'https://shenmo-statics.oss-cn-beijing.aliyuncs.com/li_bai.jpg',
// };

const Mobile = ({ openToast }) => {
  const [searchParams] = useSearchParams();
  const character = useRef(null);
  // 0-未初始化 1-初始化中 2-初始化结束
  const initState = useRef(0);
  const [init, setInit] = useState(false);
  const [sayIng, setSayIng] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [showRecord, setShowRecord] = useState(false);
  const [messageList, setMessageList] = useState([]);
  const messageListTemp = useRef(null);
  const [active, setActive] = useState(false);
  var originalSetItem = localStorage.setItem;
  localStorage.setItem = function (key, newValue) {
    var setItemEvent = new Event('setItemEvent');
    setItemEvent.newValue = newValue;
    window.dispatchEvent(setItemEvent);
    originalSetItem.apply(this, arguments);
  };
  window.addEventListener('setItemEvent', function (e) {
    // alert(e.newValue);
    if (e.newValue === true && !sayIng) {
      setSayIng(true);
    }
    // console.log(e, 'eee');
    // console.log(e.newValue, 'eee');
  });
  // 是否取消发送
  const isCancel = useRef(false);
  const getcharacterById = async () => {
    try {
      const character_id = searchParams.get('character_id');
      if (
        character_id === undefined ||
        character_id === null ||
        character_id === ''
      ) {
        throw new Error('角色不存在，初始化失败');
      }
      // const scheme = window.location.protocol;
      // const url = scheme + '//' + getHostName() + '/characters';
      const url =
        'https://api.bubble.shenmo-ai.com/books/characters?character_id=' +
        character_id;
      const headers = {
        'Content-Type': 'application/json',
      };
      const response = await fetch(url, {
        method: 'GET',
        headers: headers,
      });
      if (response.status < 200 || response.status >= 300) {
        throw new Error('角色获取失败，初始化失败');
      }
      const characterList = await response.json();
      character.current = characterList.data;
      console.log(characterList.data, '123');
      console.log(character_id, 'character_id');
      // for (let i = 0; i < characterList.data.length; i++) {
      //   if (characterList.data[i].character_id === character_id) {
      //     character.current = characterList.data[i];
      //     console.log(character.current, 'character.current');
      //     break;
      //   }
      // }
      if (character.current === null) {
        throw new Error('角色获取失败，初始化失败');
      }
      // 设置标题为角色名
      document.title = character.current.name;
    } catch (error) {
      throw error;
    }
  };
  const initPage1 = async () => {
    console.log('12322');
  };
  const initPage = async () => {
    // 标题置空
    document.title = '';
    document.addEventListener('contextmenu', event => {
      event.preventDefault();
    });
    try {
      await _checkAvailable();
    } catch (error) {
      initPageEnd();
      openToast(error.message, 'error');
    }
    try {
      await getcharacterById();
      const urlParams = getUrlParams();
      const urlOptions = {
        ...urlParams,
        character_id: character.current.character_id,
      };
      _connect(urlOptions, {
        onConnected: () => {
          initPageEnd();
          messageListTemp.current = [];
          setMessageList([]);
        },
        onAnswer: event => {
          _handleAnswer(event, {
            onData: updateMessageList,
            onEnd: () => {
              setDisabled(false);
              setSayIng(false);
              console.log('说完了');
            },
          });
        },
        onClosed: (code, reason) => {
          initPageEnd();
          setDisabled(true);
          setSayIng(true);
          if (code !== 1000) {
            openToast(`${reason}，请刷新页面再试`, 'error');
          }
        },
      });
    } catch (error) {
      console.log('websocket connect error', error);
      initPageEnd();
      openToast('初始化失败，请刷新页面再试1', 'error');
    }
  };

  const getUrlParams = () => {
    const urlParams = {};
    const keys = searchParams.keys();
    let end = false;
    while (!end) {
      const next = keys.next();
      // 结束
      if (!next.done) {
        const key = next.value;
        urlParams[key] = searchParams.get(key);
      } else {
        end = true;
      }
    }
    return urlParams;
  };

  const initPageEnd = () => {
    console.log('开始说话');
    initState.current = 2;
    setInit(true);
  };
  // const originalSetItem = localStorage.setItem;
  // localStorage.setItem = function (key, newValue) {
  //   const setItemEvent = new Event('setItemEvent');
  //   setItemEvent[key] = newValue;
  //   window.dispatchEvent(setItemEvent);
  //   originalSetItem.apply(this, [key, newValue]);
  // };
  // // 添加事件监听器
  // function handleSetItemEvent(event) {
  //   console.log('监听到本地存储发生变化了：', event);
  //   console.log('Key:', Object.keys(event)[0]);
  //   console.log('Value:', event[Object.keys(event)[0]]);
  // }
  // window.addEventListener('setItemEvent', handleSetItemEvent);
  // // 当不再需要时，移除事件监听器
  // window.removeEventListener('setItemEvent', handleSetItemEvent);
  const updateMessageList = (message, isInsert = true) => {
    const list = JSON.parse(JSON.stringify(messageListTemp.current));
    if (isInsert) {
      list.push(message);
    } else {
      let lastIndex = list.length - 1;
      while (lastIndex >= 0) {
        const lastMessage = list[lastIndex];
        if (lastMessage.id === message.id) {
          break;
        }
      }
      if (lastIndex >= 0) {
        list.splice(lastIndex, 1, message);
      }
    }
    messageListTemp.current = list;
    setMessageList(list);
    setTimeout(() => {
      const dom = document.querySelector('#message-list');
      if (dom !== null) {
        const distance = dom.scrollHeight - dom.clientHeight;
        dom.scroll(0, distance);
      }
    }, 300);
  };

  const onLongPressStart = () => {
    try {
      setShowRecord(true);
      setActive(true);
      isCancel.current = false;
      _startRecord({
        onData: _evaluate,
      });
      _addListener({
        onAnswer: result => {
          // 静音检测（可能会有返回值）
          if (_getRecorderState() === 'recording') {
            _stopRecord();
            setShowRecord(false);
            setActive(false);
            openToast('长时间不操作', 'error');
            return;
          }
          if (!isCancel.current) {
            console.log('shuohuazhong');
            setDisabled(true);
            // setSayIng(true);
            const text = result.data;
            const message = _createMessage(true);
            message.text = text;
            updateMessageList(message);
            _send(text);
          }
        },
        onClosed: (code, reason) => {
          if (!isCancel.current && code !== 1000) {
            _stopRecord();
            setShowRecord(false);
            setActive(false);
            openToast(reason, 'error');
          }
        },
      });
    } catch (error) {
      console.log(error);
      openToast(error.message, 'error');
    }
  };

  const onLongPressMove = event => {
    if (event.changedTouches.length > 0) {
      const dom = document.querySelector('#record-button');
      if (dom !== null) {
        const { top, right, bottom, left } = dom.getBoundingClientRect();
        const { clientX, clientY } = event.changedTouches[0];
        const isXIn = clientX > left && clientX < right;
        const isYIn = clientY > top && clientY < bottom;
        console.log(isXIn, isYIn);
        if (isXIn && isYIn) {
          setActive(true);
        } else {
          setActive(false);
        }
      }
    }
  };
  const onLongPressEnd = () => {
    setShowRecord(false);
    isCancel.current = !active;
    _stopRecord();
  };

  useEffect(() => {
    if (initState.current === 0) {
      initState.current = 1;
      initPage();
    }
    return () => {
      _disconnect(1000, 'End');
    };
  }, []);
  return (
    <div className='mobile-page'>
      <div className='chat-bg'>
        {/* {character.current && !sayIng ? (
          // <img src={character.current.image_url} loading='lazy' />
          // <img src={character.current.motion_image} />
          <img src={character.current.still_image} />
        ) : (
          <img src={character.current.motion_image} />
        )} */}
        {character.current && !sayIng && (
          <img src={character.current.still_image} />
        )}
        {character.current && sayIng && (
          <img src={character.current.motion_image} />
        )}
      </div>
      <div className='chat-container'>
        <div className='prologue'>
          {character.current ? (
            <div className='prologue-name'>
              {character.current.name}
              <div className='prologue-slogan'>{character.current.slogan}</div>
            </div>
          ) : null}
        </div>
        <div className='message-list' id='message-list'>
          {messageList.map((message, index) => (
            <div
              className={`message-item ${message.speaker}`}
              key={message.id + '_' + index}
            >
              {message.text}
            </div>
          ))}
        </div>
        <LongPressButton
          disabled={disabled}
          onLongPressStart={onLongPressStart}
          onLongPressMove={onLongPressMove}
          onLongPressEnd={onLongPressEnd}
        />
        <div className='bottom-safe-area'></div>
      </div>
      <div
        className='record-container'
        style={{ display: showRecord ? 'flex' : 'none' }}
      >
        <div className='record-icon'>
          <img src={shengbo_pink} />
        </div>
        <div className='record-tip'>{active ? '松开发送' : '松开取消'}</div>
        <div className='record-button bottom-safe-area' id='record-button'>
          <img
            src={fasong_active}
            style={{ display: active ? 'block' : 'none' }}
          />
          <img
            src={fasong_disabled}
            style={{ display: active ? 'none' : 'block' }}
          />
          <div className='shengbo'>
            <img
              src={yuying_333}
              style={{ display: active ? 'block' : 'none' }}
            />
            <img
              src={yuying_999}
              style={{ display: active ? 'none' : 'block' }}
            />
          </div>
        </div>
      </div>
      {init ? null : (
        <div className='loading-container'>
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default Mobile;
